import { DONOR_DETAILS_F, DONOR_DETAILS_S, DONOR_LIST_F, DONOR_LIST_S } from "../constants/types";

const initialState = {
    donorList: {},
    donorDetails :{}
};

const DonorReducer = (state = initialState, action) => {
    switch (action.type) {
        case DONOR_LIST_S:
            return {
                ...state, donorList: action.payload.data
            }
        case DONOR_LIST_F:
            return {
                ...state, donorList: action.payload.message
            }
        case DONOR_DETAILS_S:
            return {
                ...state, donorDetails: action.payload.data
            }
        case DONOR_DETAILS_F:
            return {
                ...state, donorDetails: action.payload.message
            }
        default:
            return state;
    }
}
export default DonorReducer