/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { debounce } from "../../../utils";
import { Link } from "react-router-dom";
import View from "../../../components/svg/view";
import ".././strategic.scss";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../../components/layout/Breadcrumb";
import { strategicPartnerlist } from "../../../actions/strategic-partner";
import Right from "../../../components/svg/right";
import Wrong from "../../../components/svg/wrong";
import Dmodel from "../../../components/common/delete";

const list = [
  {
    name: "Giving Partner Mangement",
    link: "/strategic",
    isactive: false,
  },
  {
    name: "Giving Partner List",
    link: "/strategic ",
    isactive: true,
  },
];

const PendingStrategic = () => {
  const pendingstrategicpartnerlist = useSelector(
    (state) =>
      state.StrategicReducer.pendingstrategic.adminPendingStrategicPartner
  );

  const dispatch = useDispatch();
  const [totalRecords, settotalRecords] = useState(0);

  const [arg, setArg] = useState({
    page: 1,
    limit: 10,
    search: "",
    column: "",
    order: "",
  });
  const [rejectresaon, setrejectResaon] = useState("");
  const [viewmodel, setViewmodel] = useState(false);

  useEffect(() => {
    dispatch(strategicPartnerlist(arg)).then((res) => {
      settotalRecords(res?.data?.total_records || 0);
    });
  }, [arg]);

  const viewrejcetcpopup = (value) => {
    setViewmodel(true);
    setrejectResaon(value);
  };
  const customStyles = {
    headCells: {
      style: {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
    cells: {
      style: {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Giving Partner Name",
      selector: (row) => row.name,
      sortable: true,
      sortName: "name",
    },
    {
      name: "User name",
      selector: (row) => row.userName,
    },
    {
      name: "Email",
      selector: (row) => row.emailAddress,
    },
    {
      name: "Status",
      selector: (row) => row.adminVerification,
      cell: (row) => (
        <div className="status-box">
          {row.adminVerification === "Pending" ? (
            <span className="pending">Pending</span>
          ) : (
            <span className="reject">Reject</span>
          )}
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => row.action,
      minWidth: "180px",
      cell: (row) => (
        <div className="action-btn">
          {row.adminVerification === "Pending" ? (
            <>
              {" "}
              <Link
                className="btn right"
                title="Approved"
                to={`/strategic/strategic_view/${row._id}`}
              >
                {" "}
                <Right />{" "}
              </Link>
              <Link
                to={`/strategic/strategic_view/${row._id}`}
                className="btn wrong"
                title="Reject"
              >
                {" "}
                <Wrong />{" "}
              </Link>
            </>
          ) : (
            <>
              <Link
                className="btn right"
                title="Approved"
                to={`/strategic/strategic_view/${row._id}`}
              >
                {" "}
                <Right />{" "}
              </Link>
              <button
                className="btn right"
                title="view"
                onClick={() => viewrejcetcpopup(row.rejectReason)}
              >
                {" "}
                <View />{" "}
              </button>
            </>
          )}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const onChangeFun = (e) => {
    setArg({ ...arg, search: e });
  };
  const optimizedFn = debounce(onChangeFun);

  const handlePerRowsChange = async (newPerPage, page) => {
    setArg({ ...arg, limit: newPerPage });
  };

  const handlePageChange = (page) => {
    setArg({ ...arg, page: page });
  };

  const onSortChange = (column, direction) => {
    setArg({ ...arg, column: column?.sortName, order: direction });
  };

  return (
    <>
      <div className="strategic-section">
        <div className="top-box">
          <Breadcrumb list={list} />
          <h2>Giving Partner Management </h2>
        </div>
        <div className="section-inner">
          <div className="d-flex justify-content-between">
            <div className="search-box">
              <input
                type="text"
                placeholder="Search By Giving Partner Name"
                className="search-input"
                onChange={(e) => optimizedFn(e.target.value)}
              />
            </div>
          </div>
          <DataTable
            border
            columns={columns}
            data={pendingstrategicpartnerlist}
            onSort={onSortChange}
            pagination
            paginationServer
            paginationTotalRows={totalRecords}
            onChangeRowsPerPage={handlePerRowsChange}
            customStyles={customStyles}
            onChangePage={handlePageChange}
            dense
          />
        </div>
      </div>

      <Dmodel
        name="Rejected Reason"
        setmodel={setViewmodel}
        ismodel={viewmodel}
        from="strategicRejectview"
        rejectresaon={rejectresaon}
      />
    </>
  );
};

export default PendingStrategic;
