import React, { useRef } from "react";
import Select from "react-select";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { getVideoUrl } from "../../utils";
import { addTOCity } from "../../actions/registration";
import "../../components/common/img-upload/upload.scss";
import store from "../../store";
import Delete from "../svg/delete";
import ReactQuill from "react-quill";
import CreatableSelect from "react-select/creatable";
import { AsyncPaginate } from "react-select-async-paginate";

export const renderField = ({
  input,
  type,
  placeholder,
  className,
  inputclass,
  readOnly,
  meta: { touched, error },
}) => (
  <>
    <div className={className}>
      <input
        type={type}
        {...input}
        readOnly={readOnly}
        className={inputclass}
        placeholder={placeholder}
      />
      <div className="input-error">
        {touched && error && <span className="mb-0 text-danger">{error}</span>}
      </div>
    </div>
  </>
);

export const renderNumberField = ({
  input,
  type,
  placeholder,
  formatStyle,
  className,
  inputclass,
  readOnly,
  meta: { touched, error },
}) => (
  <>
    <div className={className}>
      <NumberFormat
        {...input}
        format={formatStyle}
        className={inputclass}
        placeholder={placeholder}
        readOnly={readOnly}
      />
      <div className="input-error">
        {touched && error && <span className="mb-0 text-danger">{error}</span>}
      </div>
    </div>
  </>
);

export const rendertextarea = ({
  input,
  type,
  placeholder,
  className,
  inputclass,
  readOnly,
  meta: { touched, error },
}) => (
  <>
    <div className={className}>
      <textarea
        {...input}
        readOnly={readOnly}
        className={inputclass}
        placeholder={placeholder}
      ></textarea>
      <div className="input-error">
        {touched && error && <span className="mb-0 text-danger">{error}</span>}
      </div>
    </div>
  </>
);

export const renderSelectField = ({
  input,
  options,
  className,
  isMulti,
  readOnly,
  mutator,
  placeholder,
  isCountryField,
  isStateField,
  setselectedCountry,
  setselectedState,
  defaultValue,
  disabled,
  onValueChange,
  meta: { touched, error },
}) => (
  <>
    <div className={className}>
      <Select
        {...input}
        value={input.value}
        isMulti={isMulti}
        onChange={(e) => {
          if (isCountryField) {
            mutator("state", "");
            mutator("city", "");
            setselectedCountry(e);
            input.onChange(e);
          } else if (isStateField) {
            mutator("city", "");
            setselectedState(e);
            input.onChange(e);
          } else {
            if (onValueChange) {
              mutator("nonprofitorcausedata", "");
              onValueChange(e);
            }
            input.onChange(e);
          }
        }}
        isDisabled={disabled}
        options={options}
        classNamePrefix="select"
        placeholder={placeholder}
      />
      <div className="input-error">
        {touched && error && <span className="mb-0 text-danger">{error}</span>}
      </div>
    </div>
  </>
);

export const RenderOptimisedSelectField = ({
  className,
  loadOptions,
  isMulti,
  closeMenuOnSelect,
  onChange,
  key,
  value,
}) => (
  <>
    <div className={className}>
      <AsyncPaginate
        value={value}
        debounceTimeout={300}
        isMulti={isMulti}
        key={key}
        onChange={onChange}
        loadOptions={loadOptions}
        closeMenuOnSelect={closeMenuOnSelect}
        classNamePrefix="select"
      />
    </div>
  </>
);

export const renderCityField = ({
  input,
  options,
  className,
  isMulti,
  readOnly,
  mutator,
  placeholder,
  isCountryField,
  isStateField,
  setselectedCountry,
  setselectedState,
  defaultValue,
  disabled,
  meta: { touched, error },
}) => (
  <>
    <div className={className}>
      <CreatableSelect
        {...input}
        value={input.value}
        isMulti={isMulti}
        onChange={(e) => {
          if (isCountryField) {
            mutator("state", "");
            mutator("city", "");
            setselectedCountry(e);
            input.onChange(e);
          } else if (isStateField) {
            mutator("city", "");
            setselectedState(e);
            input.onChange(e);
          } else {
            input.onChange(e);
          }
        }}
        isDisabled={disabled}
        options={options}
        classNamePrefix="select"
        placeholder={placeholder}
      />
      <div className="input-error">
        {touched && error && <span className="mb-0 text-danger">{error}</span>}
      </div>
    </div>
  </>
);

export const renderMultiSelectInput = ({
  input,
  options,
  className,
  isMulti,
  readOnly,
  isLoading,
  mutator,
  placeholder,
  isCountryField,
  isStateField,
  setselectedCountry,
  setselectedState,
  defaultValue,
  disabled,
  isOptionSelected,
  inputOption,
  setPage,
  page,
  MenuList,
  loadOptions,
  meta: { touched, error },
}) => (
  <>
    <div className={className}>
      <Select
        {...input}
        classNamePrefix="reactMultiSelect"
        isSearchable={false}
        className={className}
        options={options}
        placeholder={placeholder}
        isMulti={isMulti}
        closeMenuOnSelect={false}
        hideSelectedOptions={true}
        // isOptionSelected={isOptionSelected}
        // selectOption={selectOption}
        // hasNextPage={hasNextPage}
        // loadNextPage={loadNextPage}
        // isNextPageLoading={isNextPageLoading}
        // onChange={onChange}
        isLoading={isLoading}
        components={{
          // DropdownIndicator: BottomIcon,
          // MenuList,
          Option: inputOption,
        }}
      // onMenuScrollToBottom={() => {
      //   loadOptions();
      //   setPage(page + 1);
      // }}
      />
    </div>
  </>
);

export const EditRenderSelectField = ({
  input,
  options,
  className,
  isMulti,
  readOnly,
  mutator,
  placeholder,
  isCountryField,
  isStateField,
  setselectedCountry,
  disabled,
  meta: { touched, error },
}) => (
  <>
    <div className={className}>
      <Select
        {...input}
        value={input.value}
        // defaultValue={{ value: "Help Girl Child", label: "Help Girl Child" }}
        isMulti={isMulti}
        onChange={async (e) => {
          if (isCountryField) {
            mutator("state", "");
            mutator("city", "");
            setselectedCountry(e);
            input.onChange(e);
          } else if (isStateField) {
            mutator("city", "");
            input.onChange(e);
            await store.dispatch(addTOCity({ stateId: e?.value }));
          } else {
            input.onChange(e);
          }
        }}
        isDisabled={disabled}
        options={options}
        classNamePrefix="select"
        placeholder={placeholder}
      />
      <div className="input-error">
        {touched && error && <span className="mb-0 text-danger">{error}</span>}
      </div>
    </div>
  </>
);

export const EditSelectField = ({
  options,
  className,
  isMulti,
  data,
  setselectedState,
  setselectedCity,
  placeholder,
  isCountryField,
  isStateField,
  setselectedCountry,
  disabled,
}) => (
  <>
    <div className={className}>
      <Select
        value={data}
        isMulti={isMulti}
        onChange={async (e) => {
          if (isCountryField) {
            setselectedCountry(e);
          } else if (isStateField) {
            setselectedState(e);
            await store.dispatch(addTOCity({ stateId: e?.value }));
            setselectedCity("");
          } else {
            setselectedCity(e);
          }
        }}
        isDisabled={disabled}
        options={options}
        classNamePrefix="select"
        placeholder={placeholder}
      />
    </div>
  </>
);

export const EditCityField = ({
  options,
  className,
  isMulti,
  data,
  setselectedState,
  setselectedCity,
  placeholder,
  isCountryField,
  isStateField,
  setselectedCountry,
  disabled,
}) => (
  <>
    <div className={className}>
      <CreatableSelect
        value={data}
        isMulti={isMulti}
        onChange={async (e) => {
          if (isCountryField) {
            setselectedCountry(e);
          } else if (isStateField) {
            setselectedState(e);
            await store.dispatch(addTOCity({ stateId: e?.value }));
            setselectedCity("");
          } else {
            setselectedCity(e);
          }
        }}
        isDisabled={disabled}
        options={options}
        classNamePrefix="select"
        placeholder={placeholder}
      />
    </div>
  </>
);

export const renderRadioButton = ({
  input,
  input: { onChange, checked, name, type, value },
  readOnly,
  className,
  children,
  setActive,
  disabled,
  meta: { touched, error },
}) => (
  <>
    <label className={className}>
      {children}
      <input
        type={type}
        value={value}
        name={name}
        checked={checked}
        onChange={(e) => {
          setActive(e.target.value);
          input.onChange(e);
        }}
        disabled={disabled}
      />
      <span className="checkmark"></span>
    </label>
    <div className="input-error">
      {touched && error && <span className="mb-0 text-danger">{error}</span>}
    </div>
  </>
);

export const renderRadioButtonCommon = ({
  input,
  marginclass,
  readOnly,
  className,
  children,
  setActive,
  disabled,
  meta: { touched, error },
}) => (
  <div className={`${marginclass}`}>
    <label className={className}>
      {children}
      <input {...input} disabled={disabled} />

      <span className="checkmark"></span>
    </label>
    <div className="input-error">
      {touched && error && <span className="mb-0 text-danger">{error}</span>}
    </div>
  </div>
);

export const renderSelect = ({
  input,
  name,
  option,
  list,
  placeholder,
  id,
  className,
  meta: { touched, error },
}) => {
  return (
    <>
      <select {...input} className={className}>
        {list.map((value, i) => (
          <option key={value.name} value={value.option}>
            {" "}
            {value.name}{" "}
          </option>
        ))}
      </select>
      <div className="input-error">
        {touched && error && (
          <span className="mb-0 text-right text-danger">{error}</span>
        )}
      </div>
    </>
  );
};

export const RenderVideoField = ({
  videoArray,
  setVideoArray,
  videoSourceURLs,
  setVideoSourceURLs,
  videoSourceFiles,
  setVideoSourceFiles,
  setDeletedVideoArray,
  oldVideos = [],
  moduleName,
  isMultiple,
  oneVideoValidation = false,
  containOldImages,
}) => {
  const metaData = useSelector((state) => state.metaReducer);
  let ref = useRef();

  const handleFileChange = (event) => {
    let fileArray = Object.values(event.target.files) || [];
    if (fileArray.length) {
      setVideoSourceFiles(fileArray);
      fileArray.forEach((_, ind) => {
        const file = event.target.files[ind];
        const url = URL.createObjectURL(file);
        setVideoSourceURLs([...videoSourceURLs, url]);
      });
    }
  };

  const onDelete = (val) => {
    let filteredVideos = videoArray.filter((o) => o?.name !== val);
    setVideoArray(filteredVideos);
    setDeletedVideoArray({ name: val });
  };

  const deleteLocalImageFile = (index) => {
    let filteredVideos = videoSourceFiles.filter((_, i) => i !== index);
    let filteredVideosURL = videoSourceURLs.filter((_, i) => i !== index);
    setVideoSourceFiles(filteredVideos);
    setVideoSourceURLs(filteredVideosURL);
  };

  return (
    <>
      <div className="d-flex upload__image-wrapper">
        <div className="upload-input">
          {oneVideoValidation ? (
            videoArray?.length + videoSourceFiles?.length < 1 && (
              <button
                type="button"
                className="btn btn-light upload-btn"
                onClick={() => {
                  ref.current.click();
                }}
              >
                +
              </button>
            )
          ) : (
            <button
              type="button"
              className="btn btn-light upload-btn"
              onClick={() => {
                ref.current.click();
              }}
            >
              +
            </button>
          )}
        </div>
        <div className="display-video img-map-box">
          {containOldImages && videoArray?.length
            ? videoArray.map((val, i) => {
              return (
                <>
                  <div className="video-box">
                    <div key={val?.url} className="video-box image-item">
                      <a
                        href={`${val?.url}`}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <img
                          src={getVideoUrl(
                            `${metaData?.mediaURL}`,
                            `${moduleName}`,
                            `${val?.thumbnail}`,
                            true
                          )}
                          alt={val?.name}
                          height={80}
                          width={80}
                        />
                      </a>
                    </div>
                    <div className="image-item__btn-wrapper">
                      <button
                        type="button"
                        className="delete img-btn"
                        onClick={() => {
                          onDelete(val?.name);
                        }}
                      >
                        <Delete />
                      </button>
                    </div>
                  </div>
                </>
              );
            })
            : ""}
          {videoSourceURLs?.length
            ? videoSourceURLs.map((url, ind) => {
              return (
                <>
                  <div className="video-box">
                    <div key={ind?.url} className="video-box image-item">
                      <a href={`${url}`} target={"_blank"} rel="noreferrer">
                        <video
                          key={url}
                          src={url}
                          style={{
                            width: "90px",
                            height: "90px",
                            objectFit: "cover",
                          }}
                        />
                      </a>
                    </div>
                    <div className="image-item__btn-wrapper">
                      <button
                        type="button"
                        className="delete img-btn"
                        onClick={() => deleteLocalImageFile(ind)}
                      >
                        <Delete />
                      </button>
                    </div>
                  </div>
                </>
              );
            })
            : null}
        </div>
      </div>
      <input
        style={{ display: "none" }}
        ref={ref}
        multiple={isMultiple}
        onChange={(e) => handleFileChange(e)}
        type={"file"}
        accept="video/*"
      />
    </>
  );
};

export const renderHtmlTextEditor = ({
  input,
  placeholder,
  className,
  inputclass,
  style,
  value,
  readOnly,
  meta: { touched, error },
}) => {
  return (
    <>
      <div className={className}>
        <ReactQuill
          {...input}
          theme="snow"
          placeholder={placeholder}
          style={style}
          readOnly={readOnly}
          inputclass={inputclass}
        />
        <div className="input-error">
          {touched && error && (
            <span className="mb-0 text-danger">{error}</span>
          )}
        </div>
      </div>
    </>
  );
};
