import { TRANSACTION_DETAIL_F, TRANSACTION_DETAIL_S, TRANSACTION_LIST_F, TRANSACTION_LIST_S } from "../constants/types";

const initialState = {
    transaction:[],
    transctiondetail:[]
};

const TransactionReducer = (state = initialState, action) => {
    switch (action.type) {
        case TRANSACTION_LIST_S:
            return {
                ...state, transaction:action.payload.data
            }
        case TRANSACTION_LIST_F:
            return {
                ...state, transaction: action.payload.message
            }
        case TRANSACTION_DETAIL_S:
            return {
                ...state, transctiondetail: action.payload.data
            }
        case TRANSACTION_DETAIL_F:
            return {
                ...state, transctiondetail: action.payload.message
            }
        default:
            return state;
    }
}
export default TransactionReducer