export const SubAdminRolesData = [
  {
    role: "Dashboard",
    view: "view",
    id: "dashboard",
    to: "/dashboard",
  },
  {
    role: "Community Management",
    view: "view",
    add: "add",
    edit: "edit",
    delete: "delete",
    id: "communitymanagement",
    to: "/community",
  },

  {
    ischilderan: true,
    parent: "Master Management",
    role: "New Nonprofit",
    view: "view",
    id: "newcharity",
    to: "/master",
  },
  {
    ischilderan: true,
    parent: "Master Management",
    role: "Nonprofit Management",
    add: "add",
    edit: "edit",
    delete: "delete",
    status: "status",
    id: "charitymanagement",
    to: "/master/charity_management",
  },
  {
    ischilderan: true,
    parent: "Master Management",
    role: "Cause Management",
    add: "add",
    edit: "edit",
    delete: "delete",
    status: "status",
    id: "causemanagement",
    to: "/master/cause_management",
  },
  {
    ischilderan: true,
    parent: "Master Management",
    role: "Nonprofit Category",
    // view: "view",
    add: "add",
    edit: "edit",
    delete: "delete",
    status: "status",
    id: "charitycategory",
    to: "/master/charity_category_management",
  },
  {
    ischilderan: true,
    parent: "Content Management",
    role: "Homepage",
    edit: "edit",
    id: "homepage",
    to: "/content_management/homepage",
  },
  {
    ischilderan: true,
    parent: "Content Management",
    role: "Privacy policy",
    edit: "edit",
    id: "privacypolicy",
    to: "/content_management/privacy_policy",
  },
  {
    ischilderan: true,
    parent: "Content Management",
    role: "Terms Of Use",
    edit: "edit",
    id: "terms&condition",
    to: "/content_management/terms_condition",
  },
  {
    ischilderan: true,
    parent: "Content Management",
    role: "Leadership",
    add: "add",
    edit: "edit",
    delete: "delete",
    id: "leadership",
    to: "/content_management/leadership",
  },
  {
    ischilderan: true,
    parent: "Content Management",
    role: "About Us",
    edit: "edit",
    id: "aboutus",
    to: "/content_management/about_us",
  },
  {
    ischilderan: true,
    parent: "Content Management",
    role: "FAQ Management",
    add: "add",
    edit: "edit",
    delete: "delete",
    id: "faqmanagement",
    to: "/category/FAQ",
  },
  {
    ischilderan: true,
    parent: "Content Management",
    role: "FAQ Category Management",
    add: "add",
    edit: "edit",
    delete: "delete",
    id: "faqcategorymanagement",
    to: "/category",
  },
  {
    role: "Event Management",
    view: "view",
    add: "add",
    edit: "edit",
    delete: "delete",
    id: "eventmanagement",
    to: "/event",
  },

  {
    ischilderan: true,
    parent: "Giving Partner Management",
    role: "Pending/Rejected Partners",
    view: "view",
    id: "pending/rejectedpartners",
    to: "/strategic/pending_strategic_partner",
  },
  {
    ischilderan: true,
    parent: "Giving Partner Management",
    role: "Management of Approved Partners",
    view: "view",
    delete: "delete",
    status: "status",
    id: "strategicmanagement",
    to: "/strategic",
  },
  {
    role: "Donor Management",
    view: "view",
    add: "add",
    edit: "edit",
    delete: "delete",
    status: "status",
    id: "donormanagement",
    to: "/donor",
  },
  {
    role: "Transaction Management",
    view: "view",
    id: "transactionmanagement",
    to: "/transaction",
  },

  {
    role: "Badge Management",
    edit: "edit",
    delete: "delete",
    id: "badgemanagement",
    to: "/badge",
  },
  {
    role: "Notification Management",
    add: "add",
    view: "view",
    id: "notificationmanagement",
    to: "/send_notification",
  },

  {
    role: "Contact Us From",
    view: "view",
    id: "contactusfrom",
    to: "/category/contact_form",
  },
  {
    role: "Report Post",
    view: "view",
    delete: "delete",
    id: "reportpost",
    to: "/reporte_post",
  },
  {
    role: "Subscribed Donor",
    view: "view",
    id: "subscribeddonor",
    to: "/subscribed_donor",
  },
  {
    role: "Sub Admin",
    view: "view",
    add: "add",
    edit: "edit",
    delete: "delete",
    status: "status",
    id: "subadmin",
    to: "/sub_admin",
  },
  {
    role: "Service Charges",
    edit: "edit",
    id: "servicecharges",
    to: "/manage_service_charges",
  },
  {
    role: "Message",
    add: "add",
    view: "view",
    id: "chat",
    to: "/chat",
  },
];
