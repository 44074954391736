import {
  API_DONOR_ADD,
  API_DONOR_DELETE,
  API_DONOR_DETAILS,
  API_DONOR_LIST,
  API_DONOR_STATUS,
  API_DONOR_UPDATE,
} from "../constants/api";
import {
  API,
  DONOR_ADD_F,
  DONOR_ADD_S,
  DONOR_DETAILS_F,
  DONOR_DETAILS_S,
  DONOR_LIST_F,
  DONOR_LIST_S,
  DONOR_UPDATE_F,
  DONOR_UPDATE_S,
} from "../constants/types";

export const donorList = (data) => ({
  type: API,
  payload: {
    url: API_DONOR_LIST,
    method: "POST",
    data: data,
    success: (data) => ({
      type: DONOR_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: DONOR_LIST_F,
      payload: data,
    }),
  },
});

export const donorAdd = (data) => ({
  type: API,
  payload: {
    url: API_DONOR_ADD,
    method: "POST",
    data: data,
    success: (data) => ({
      type: DONOR_ADD_S,
      payload: data,
    }),
    error: (data) => ({
      type: DONOR_ADD_F,
      payload: data,
    }),
  },
});

export const donorDetails = (data) => ({
  type: API,
  payload: {
    url: API_DONOR_DETAILS,
    method: "POST",
    data: data,
    success: (data) => ({
      type: DONOR_DETAILS_S,
      payload: data,
    }),
    error: (data) => ({
      type: DONOR_DETAILS_F,
      payload: data,
    }),
  },
});

export const donorUpdate = (id, data) => ({
  type: API,
  payload: {
    url: API_DONOR_UPDATE + id,
    method: "POST",
    data: data,
    success: (data) => ({
      type: DONOR_UPDATE_S,
      payload: data,
    }),
    error: (data) => ({
      type: DONOR_UPDATE_F,
      payload: data,
    }),
  },
});

export const donorDelete = (id) => ({
  type: API,
  payload: {
    url: API_DONOR_DELETE + id,
    method: "DELETE",
  },
});

export const donorStatus = (id) => ({
  type: API,
  payload: {
    url: API_DONOR_STATUS + id,
    method: "POST",
  },
});
