import {
  API_CHANGE_PASSWORD,
  API_FORGOT_PASSWORD,
  API_LOGIN,
  API_REFERSH_TOKEN,
  API_RESET_PASSWORD,
  API_SUPER_ADMIN_LOGIN,
} from "../constants/api";
import {
  API,
  LOGIN_S,
  LOGIN_F,
  LOGOUT,
  ADMIN_CHANGE_PASSWORD_S,
  ADMIN_CHANGE_PASSWORD_F,
} from "../constants/types";

export const doLogin = (data) => ({
  type: API,
  payload: {
    url: API_LOGIN,
    method: "POST",
    data: data,
    success: (data) => ({
      type: LOGIN_S,
      payload: data,
    }),
    error: (data) => ({
      type: LOGIN_F,
      payload: data,
    }),
  },
});

export const doSuperAdminLogin = (data) => ({
  type: API,
  payload: {
    url: API_SUPER_ADMIN_LOGIN,
    method: "POST",
    data: data,
    success: (data) => ({
      type: LOGIN_S,
      payload: data,
    }),
    error: (data) => ({
      type: LOGIN_F,
      payload: data,
    }),
  },
});

export const doLogout = () => ({
  type: LOGOUT,
});


export const adminChangePassword = (data) => ({
  type: API,
  payload: {
    url: API_CHANGE_PASSWORD,
    method: "POST",
    data: data,
    success: (data) => ({
      type: ADMIN_CHANGE_PASSWORD_S,
      payload: data,
    }),
    error: (data) => ({
      type: ADMIN_CHANGE_PASSWORD_F,
      payload: data,
    }),
  },
});
export const Remembermereferesh = (data) => ({
  type: API,
  payload: {
    url: API_REFERSH_TOKEN,
    method: "POST",
    data: data,
  },
});


export const adminForgotPassword = (data) => ({
  type: API,
  payload: {
    url: API_FORGOT_PASSWORD,
    method: "POST",
    data: data,
    },
});

export const adminResetPassword = (data) => ({
  type: API,
  payload: {
    url: API_RESET_PASSWORD,
    method: "POST",
    data: data,
    },
});