import {
  API_COMMUNITY_DELETE_ID,
  API_COMMUNITY_GETALL,
  API_COMMUNITY_GET_ID,
  API_COMMUNITY_UPDATE_ID,
  API_COMMUNITY_CREATE,
  API_COMMUNITY_HIDE,
} from "../constants/api";
import {
  API,
  COMMUNITY_ADD_FAIL,
  COMMUNITY_ADD_SUCCESS,
  COMMUNITY_EDIT_FAIL,
  COMMUNITY_EDIT_SUCCESS,
  COMMUNITY_LISTING_FAIL,
  COMMUNITY_LISTING_SUCCESS,
  COMMUNITY_UPDATE_FAIL,
  COMMUNITY_UPDATE_SUCCESS,
} from "../constants/types";

export const communityList = (data) => ({
  type: API,
  payload: {
    url: API_COMMUNITY_GETALL,
    method: "POST",
    data: data,
    success: (data) => ({
      type: COMMUNITY_LISTING_SUCCESS,
      payload: data,
    }),
    error: (data) => ({
      type: COMMUNITY_LISTING_FAIL,
      payload: data,
    }),
  },
});

export const communityCreat = (data) => ({
  type: API,
  payload: {
    url: API_COMMUNITY_CREATE,
    method: "POST",
    data: data,
    success: (data) => ({
      type: COMMUNITY_ADD_SUCCESS,
      payload: data,
    }),
    error: (data) => ({
      type: COMMUNITY_ADD_FAIL,
      payload: data,
    }),
  },
});

export const communityEdit = (id) => ({
  type: API,
  payload: {
    url: API_COMMUNITY_GET_ID + `${id}`,
    method: "GET",
    success: (data) => ({
      type: COMMUNITY_EDIT_SUCCESS,
      payload: data,
    }),
    error: (data) => ({
      type: COMMUNITY_EDIT_FAIL,
      payload: data,
    }),
  },
});
export const communityDelete = (id) => ({
  type: API,
  payload: {
    url: `${API_COMMUNITY_DELETE_ID}/${id}`,
    method: "DELETE",
    data: id,
  },
});

export const communityUpdate = (data, id) => ({
  type: API,
  payload: {
    url: API_COMMUNITY_UPDATE_ID + `${id}`,
    method: "PATCH",
    data: data,
    success: (data) => ({
      type: COMMUNITY_UPDATE_SUCCESS,
      payload: data,
    }),
    error: (data) => ({
      type: COMMUNITY_UPDATE_FAIL,
      payload: data,
    }),
  },
});

export const communityhideunhide = (id) => ({
  type: API,
  payload: {
    url: API_COMMUNITY_HIDE + id,
    method: "POST",
  },
});
