import React from "react";
import { Link } from "react-router-dom";
import "./breadcrumb.scss";

const Breadcrumb = (props) => {
  const { list } = props;
  
  return (
    <>
      <div className="breadcrumb-box">
        <ul className="breadcrumb-ul">
          {/* <li> <Link to="#">Home</Link> </li> */}
          {list?.map((i,index) => (
            
            <li key={i?.name}>
              
              {!i.isactive ? (
                <Link to={i.link}>
                  <h4>{i.name}</h4>
                </Link>
              ) : (
                <h4 className="mainPage">{i.name}</h4>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
export default Breadcrumb;
