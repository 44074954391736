import { COMMUNITY_EDIT_FAIL, COMMUNITY_EDIT_SUCCESS, COMMUNITY_LISTING_FAIL, COMMUNITY_LISTING_SUCCESS } from "../constants/types";

const initialState = {
    communityList: {},
    editCommunity: {},
};

const CommunityReducer = (state = initialState, action) => {
    switch (action.type) {
        case COMMUNITY_LISTING_SUCCESS:
            return {
                ...state, communityList: action.payload.data
            }
        case COMMUNITY_LISTING_FAIL:
            return {
                ...state, communityList: action.payload.message
            }
        case COMMUNITY_EDIT_SUCCESS:
            return {
                ...state, editCommunity: action.payload.data
            }
        case COMMUNITY_EDIT_FAIL:
            return {
                ...state, editCommunity: action.payload.message
            }
        default:
            return state;
    }
}
export default CommunityReducer