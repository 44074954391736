export const API = 'API'
export const API_REQUEST = 'API_REQUEST'
export const API_SUCCESS = 'API_SUCCESS'
export const API_ERROR = 'API_ERROR'

export const LOGIN_S = 'LOGIN_SUCCESS'
export const LOGIN_F = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'

export const FETCH_META_S = 'FETCH_META_S'
export const FETCH_META_F = 'FETCH_META_F'

export const CAUSE_S = 'CAUSE_SUCCESS'
export const CAUSE_F = 'CAUSE_FAIL'


export const CATEGORY_S = 'CATEGORY_S'
export const CATEGORY_F = 'CATEGORY_F'

export const ADD_CHARITY_DETAILS_STEPS_1 = 'ADD_CHARITY_DETAILS_STEPS_1'
export const ADD_CHARITY_DETAILS_STEPS_2 = 'ADD_CHARITY_DETAILS_STEPS_2'
export const ADD_CHARITY_DETAILS_STEPS_3 = 'ADD_CHARITY_DETAILS_STEPS_3'
export const ADD_CHARITY_DETAILS_STEPS_4 = 'ADD_CHARITY_DETAILS_STEPS_4'

export const CHARITY_LISTING_S = 'CHARITY_LISTING_S'
export const CHARITY_LISTING_F = 'CHARITY_LISTING_F'

export const CHARITY_DETAIL_S = 'CHARITY_DETAIL_S'
export const CHARITY_DETAIL_F = 'CHARITY_DETAIL_F'

export const CHARITY_VERIFICTAION_S = 'CHARITY_VERIFICTAION_S'
export const CHARITY_VERIFICTAION_F = 'CHARITY_VERIFICTAION_F'

export const ADMIN_CHANGE_PASSWORD_S = 'ADMIN_CHANGE_PASSWORD_S'
export const ADMIN_CHANGE_PASSWORD_F = 'ADMIN_CHANGE_PASSWORD_F'


// admin cause 
export const CAUSE_LISTING_SUCCESS = 'CAUSE_LISTING_SUCCESS'
export const CAUSE_LISTING_FAIL = 'CAUSE_LISTING_FAIL'

export const CAUSE_ADD_SUCCESS = 'CAUSE_ADD_SUCCESS'
export const CAUSE_ADD_FAIL = 'CAUSE_ADD_FAIL'

export const CAUSE_DETAIL_SUCCESS = 'CAUSE_DETAIL_SUCCESS'
export const CAUSE_DETAIL_FAIL = 'CAUSE_DETAIL_FAIL'

export const CAUSE_DELETE_SUCCESS = 'CAUSE_DELETE_SUCCESS'
export const CAUSE_DELETE_FAIL = 'CAUSE_DELETE_FAIL'

export const CAUSE_EDIT_SUCCESS = 'CAUSE_EDIT_SUCCESS'
export const CAUSE_EDIT_FAIL = 'CAUSE_EDIT_FAIL'

export const CAUSE_ACTIVEINACTIVE_SUCCESS = 'CAUSE_ACTIVEINACTIVE_SUCCESS'
export const CAUSE_ACTIVEINACTIVE_FAIL = 'CAUSE_ACTIVEINACTIVE_FAIL'

// -------------------------------

// New charity Accept

export const CHARITY_LISTING_ACCEPT_SUCCESS = 'CHARITY_LISTING_ACCEPT_SUCCESS'
export const CHARITY_LISTING_ACCEPT_FAIL = 'CHARITY_LISTING_ACCEPT_FAIL'


// ---------------------------------

//  Charity Management

export const CHARITY_MANAGE_ADD_SUCCESS = 'CHARITY_MANAGE_ADD_SUCCESS'
export const CHARITY_MANAGE_ADD_FAIL = 'CHARITY_MANAGE_ADD_FAIL'

export const CHARITY_MANAGE_DETAIL_SUCCESS = 'CHARITY_MANAGE_DETAIL_SUCCESS'
export const CHARITY_MANAGE_DETAIL_FAIL = 'CHARITY_MANAGE_DETAIL_FAIL'

export const CHARITY_MANAGE_DELETE_SUCCESS = 'CHARITY_MANAGE_DELETE_SUCCESS'
export const CHARITY_MANAGE_DELETE_FAIL = 'CHARITY_MANAGE_DELETE_FAIL'

export const CHARITY_MANAGE_EDIT_SUCCESS = 'CHARITY_MANAGE_EDIT_SUCCESS'
export const CHARITY_MANAGE_EDIT_FAIL = 'CHARITY_MANAGE_EDIT_FAIL'

export const CHARITY_MANAGE_UPDATE_SUCCESS = 'CHARITY_MANAGE_UPDATE_SUCCESS'
export const CHARITY_MANAGE_UPDATE_FAIL = 'CHARITY_MANAGE_UPDATE_FAIL'

export const CHARITY_MANAGE_ACTIVEINACTIVE_SUCCESS = 'CHARITY_MANAGE_ACTIVEINACTIVE_SUCCESS'
export const CHARITY_MANAGE_ACTIVEINACTIVE_FAIL = 'CHARITY_MANAGE_ACTIVEINACTIVE_FAIL'

// -------------------------------

// Charity Category

export const CHARITY_CATEGORY_LISTING_SUCCESS = 'CHARITY_CATEGORY_LISTING_SUCCESS'
export const CHARITY_CATEGORY_LISTING_FAIL = 'CHARITY_CATEGORY_LISTING_FAIL'

export const CHARITY_CATEGORY_ADD_SUCCESS = 'CHARITY_CATEGORY_ADD_SUCCESS'
export const CHARITY_CATEGORY_ADD_FAIL = 'CHARITY_CATEGORY_ADD_FAIL'

export const CHARITY_CATEGORY_DETAIL_SUCCESS = 'CHARITY_CATEGORY_DETAIL_SUCCESS'
export const CHARITY_CATEGORY_DETAIL_FAIL = 'CHARITY_CATEGORY_DETAIL_FAIL'

export const CHARITY_CATEGORY_DELETE_SUCCESS = 'CHARITY_CATEGORY_DELETE_SUCCESS'
export const CHARITY_CATEGORY_DELETE_FAIL = 'CHARITY_CATEGORY_DELETE_FAIL'

export const CHARITY_CATEGORY_EDIT_SUCCESS = 'CHARITY_CATEGORY_EDIT_SUCCESS'
export const CHARITY_CATEGORY_EDIT_FAIL = 'CHARITY_CATEGORY_EDIT_FAIL'

export const CHARITY_CATEGORY_UPDATE_SUCCESS = 'CHARITY_CATEGORY_UPDATE_SUCCESS'
export const CHARITY_CATEGORY_UPDATE_FAIL = 'CHARITY_CATEGORY_UPDATE_FAIL'

export const CHARITY_CATEGORY_ACTIVEINACTIVE_SUCCESS = 'CHARITY_CATEGORY_ACTIVEINACTIVE_SUCCESS'
export const CHARITY_CATEGORY_ACTIVEINACTIVE_FAIL = 'CHARITY_CATEGORY_ACTIVEINACTIVE_FAIL'

// -------------------------------

// admin communitu 
export const COMMUNITY_LISTING_SUCCESS = 'COMMUNITY_LISTING_SUCCESS'
export const COMMUNITY_LISTING_FAIL = 'COMMUNITY_LISTING_FAIL'

export const COMMUNITY_ADD_SUCCESS = 'COMMUNITY_ADD_SUCCESS'
export const COMMUNITY_ADD_FAIL = 'COMMUNITY_ADD_FAIL'

export const COMMUNITY_DETAIL_SUCCESS = 'COMMUNITY_DETAIL_SUCCESS'
export const COMMUNITY_DETAIL_FAIL = 'COMMUNITY_DETAIL_FAIL'

export const COMMUNITY_DELETE_SUCCESS = 'COMMUNITY_DELETE_SUCCESS'
export const COMMUNITY_DELETE_FAIL = 'COMMUNITY_DELETE_FAIL'

export const COMMUNITY_EDIT_SUCCESS = 'COMMUNITY_EDIT_SUCCESS'
export const COMMUNITY_EDIT_FAIL = 'COMMUNITY_EDIT_FAIL'

export const COMMUNITY_UPDATE_SUCCESS = 'COMMUNITY_UPDATE_SUCCESS'
export const COMMUNITY_UPDATE_FAIL = 'COMMUNITY_UPDATE_FAIL'


export const COMMUNITY_ACTIVEINACTIVE_SUCCESS = 'COMMUNITY_ACTIVEINACTIVE_SUCCESS'
export const COMMUNITY_ACTIVEINACTIVE_FAIL = 'COMMUNITY_ACTIVEINACTIVE_FAIL'

// -------------------------------

export const ABOUT_LISTING_SUCCESS = 'ABOUT_LISTING_SUCCESS'
export const ABOUT_LISTING_FAIL = 'ABOUT_LISTING_FAIL'

export const ABOUT_UPDATE_SUCCESS = 'ABOUT_UPDATE_SUCCESS'
export const ABOUT_UPDATE_FAIL = 'ABOUT_UPDATE_FAIL'


// --------------------------------

//Giving Partner 
export const GIVING_PARTNER_DETAIL_SUCCESS = 'GIVING_PARTNER_DETAIL_SUCCESS'
export const GIVING_PARTNER_DETAIL_FAIL = 'GIVING_PARTNER_DETAIL_FAIL'




export const CAUSE_EDIT_S = 'CAUSE_EDIT_S'
export const CAUSE_EDIT_F = 'CAUSE_EDIT_F'


export const HOMEPAGE_UPDATE_S = 'HOMEPAGE_UPDATE_S'
export const HOMEPAGE_UPDATE_F = 'HOMEPAGE_UPDATE_F'

export const HOMEPAGE_DETAIL_S = 'HOMEPAGE_DETAIL_S'
export const HOMEPAGE_DETAIL_F = 'HOMEPAGE_DETAIL_F'

export const CAUSE_UPDATE_S = 'CAUSE_UPDATE_S'
export const CAUSE_UPDATE_F = 'CAUSE_UPDATE_F'

export const COUNTRY_S = 'COUNTRY_S'
export const COUNTRY_F = 'COUNTRY_F'

export const STATE_S = 'STATE_S'
export const STATE_F = 'STATE_F'

export const CITY_S = 'CITY_S'
export const CITY_F = 'CITY_F'

// -------------------------------

// admin event
export const EVENT_LISTING_SUCCESS = 'EVENT_LISTING_SUCCESS'
export const EVENT_LISTING_FAIL = 'EVENT_LISTING_FAIL'

export const EVENT_ADD_SUCCESS = 'EVENT_ADD_SUCCESS'
export const EVENT_ADD_FAIL = 'EVENT_ADD_FAIL'

export const EVENT_DELETE_SUCCESS = 'EVENT_DELETE_SUCCESS'
export const EVENT_DELETE_FAIL = 'EVENT_DELETE_FAIL'

export const EVENT_DETAILS_S = 'EVENT_DETAILS_S'
export const EVENT_DETAILS_F = 'EVENT_DETAILS_F'

export const INTERESTED_IN_EVENT_S = 'INTERESTED_IN_EVENT_S'
export const INTERESTED_IN_EVENT_F = 'INTERESTED_IN_EVENT_F'

export const GOING_IN_EVENT_S = 'GOING_IN_EVENT_S'
export const GOING_IN_EVENT_F = 'GOING_IN_EVENT_F'

export const NOTGOING_IN_EVENT_S = 'NOTGOING_IN_EVENT_S'
export const NOTGOING_IN_EVENT_F = 'NOTGOING_IN_EVENT_F'

export const EVENT_UPDATE_SUCCESS = 'EVENT_UPDATE_SUCCESS'
export const EVENT_UPDATE_FAIL = 'EVENT_UPDATE_FAIL'

export const EVENT_ACTIVEINACTIVE_SUCCESS = 'EVENT_ACTIVEINACTIVE_SUCCESS'
export const EVENT_ACTIVEINACTIVE_FAIL = 'EVENT_ACTIVEINACTIVE_FAIL'

export const EVENT_MEMBER_DELETE_S = 'EVENT_MEMBER_DELETE_S'
export const EVENT_MEMBER_DELETE_F = 'EVENT_MEMBER_DELETE_F'

// -------------------------------------

export const IMAGE_DELETE_S = 'IMAGE_DELETE_S'
export const IMAGE_DELETE_F = 'IMAGE_DELETE_F'

export const VIDEO_DELETE_S = 'VIDEO_DELETE_S'
export const VIDEO_DELETE_F = 'VIDEO_DELETE_F'


//Donor MAngement
export const DONOR_LIST_S = 'DONOR_LIST_S'
export const DONOR_LIST_F = 'DONOR_LIST_F'

export const DONOR_ADD_S = 'DONOR_ADD_S'
export const DONOR_ADD_F = 'DONOR_ADD_F'

export const DONOR_DETAILS_S = 'DONOR_DETAILS_S'
export const DONOR_DETAILS_F = 'DONOR_DETAILS_F'

export const DONOR_UPDATE_S = 'DONOR_UPDATE_S'
export const DONOR_UPDATE_F = 'DONOR_UPDATE_F'

// --------------------------------

//Post 
export const POST_ADD_S = 'POST_ADD_S'
export const POST_ADD_F = 'POST_ADD_F'

export const POST_LIST_S = 'POST_LIST_S'
export const POST_LIST_F = 'POST_LIST_F'


export const POST_UPDATE_S = 'POST_UPDATE_S'
export const POST_UPDATE_F = 'POST_UPDATE_F'

export const POST_DELETE_S = 'POST_DELETE_S'
export const POST_DELETE_F = 'POST_DELETE_F'

export const POST_HIDE_S = 'POST_HIDE_S'
export const POST_HIDE_F = 'POST_HIDE_F'

export const POST_COMMENT_LIST_S = "POST_COMMENT_LIST_S"
export const POST_COMMENT_LIST_F = "POST_COMMENT_LIST_F"

export const POST_INACTIVE_LIST_S = 'POST_INACTIVE_LIST_S'
export const POST_INACTIVE_LIST_F = 'POST_INACTIVE_LIST_F'

export const REPORT_POST_LIST_S = 'REPORT_POST_LIST_S'
export const REPORT_POST_LIST_F = 'REPORT_POST_LIST_F'
// -------------------------------

// admin event
export const LEADERSHIP_LISTING_SUCCESS = 'LEADERSHIP_LISTING_SUCCESS'
export const LEADERSHIP_LISTING_FAIL = 'LEADERSHIP_LISTING_FAIL'

export const LEADERSHIP_ADD_SUCCESS = 'LEADERSHIP_ADD_SUCCESS'
export const LEADERSHIP_ADD_FAIL = 'LEADERSHIP_ADD_FAIL'

export const LEADERSHIP_DELETE_SUCCESS = 'LEADERSHIP_DELETE_SUCCESS'
export const LEADERSHIP_DELETE_FAIL = 'LEADERSHIP_DELETE_FAIL'

export const LEADERSHIP_EDIT_SUCCESS = 'LEADERSHIP_EDIT_SUCCESS'
export const LEADERSHIP_EDIT_FAIL = 'LEADERSHIP_EDIT_FAIL'

export const LEADERSHIP_UPDATE_SUCCESS = 'LEADERSHIP_UPDATE_SUCCESS'
export const LEADERSHIP_UPDATE_FAIL = 'LEADERSHIP_UPDATE_FAIL'

export const LEADERSHIP_ACTIVEINACTIVE_SUCCESS = 'LEADERSHIP_ACTIVEINACTIVE_SUCCESS'
export const LEADERSHIP_ACTIVEINACTIVE_FAIL = 'LEADERSHIP_ACTIVEINACTIVE_FAIL'

// -------------------------------------

// -------------------------------------
// Chat Types
// -------------------------------------

export const USER_CONNECTION_STATUS = 'USER_CONNECTION_STATUS'

export const CHAT_USER_LIST_S = 'CHAT_USER_LIST_S'
export const CHAT_USER_LIST_F = 'CHAT_USER_LIST_F'

export const CHAT_LIST_S = 'CHAT_LIST_S'
export const CHAT_LIST_F = 'CHAT_F'

export const CHAT_PAGE_CHANGE = 'CHAT_PAGE_CHANGE'
export const CHAT_LIMIT_CHANGE = 'CHAT_LIMIT_CHANGE'

export const USER_LIST_LIMIT_CHANGE = 'USER_LIST_LIMIT_CHANGE'

export const CHAT_ACTIVE_USER = 'CHAT_ACTIVE_USER'

//------CMS ADMIN----

export const CMS_ADMIN_CREATE_S = 'CMS_ADMIN_CREATE_S'
export const CMS_ADMIN_CREATE_F = 'CMS_ADMIN_CREATE_F'

export const CMS_ADMIN_GETID_S = 'CMS_ADMIN_GETID_S'
export const CMS_ADMIN_GETID_F = 'CMS_ADMIN_GETID_F'

export const CMS_ADMIN_UPDATEID_S = 'CMS_ADMIN_UPDATEID_S'
export const CMS_ADMIN_UPDATEID_F = 'CMS_ADMIN_UPDATEID_F'

export const CMS_ADMIN_DELETEID_S = 'CMS_ADMIN_DELETEID_S'
export const CMS_ADMIN_DELETEID_F = 'CMS_ADMIN_DELETEID_F'

export const CMS_ADMIN_CMSPAGES_LIST_S = 'CMS_ADMIN_CMSPAGES_LIST_S'
export const CMS_ADMIN_CMSPAGES_LIST_F = 'CMS_ADMIN_CMSPAGES_LIST_F'


// Transction

export const TRANSACTION_LIST_S = 'TRANSACTION_LIST_S'
export const TRANSACTION_LIST_F = 'TRANSACTION_LIST_F'

export const TRANSACTION_DETAIL_S = 'TRANSACTION_DETAIL_S'
export const TRANSACTION_DETAIL_F = 'TRANSACTION_DETAIL_F'

// ContactUS
 export const CONTACTUS_LIST_S = 'CONTACTUS_LIST_S'
 export const CONTACTUS_LIST_F = 'CONTACTUS_LIST_F'

 export const CONTACTUS_DETAIL_S = 'CONTACTUS_DETAIL_S'
export const CONTACTUS_DETAIL_F = 'CONTACTUS_DETAIL_F'
 //FAQ 
 export const FAQ_CATEGORY_LIST_S = 'FAQ_CATEGORY_LIST_S'
 export const FAQ_CATEGORY_LIST_F = 'FAQ_CATEGORY_LIST_F' 
 export const FAQ_CATEGORY_DETAILS_S = 'FAQ_CATEGORY_DETAILS_S'
 export const FAQ_CATEGORY_DETAILS_F = 'FAQ_CATEGORY_DETAILS_F'

 export const FAQ_LIST_S = 'FAQ_LIST_S'
 export const FAQ_LIST_F = 'FAQ_LIST_F' 
 export const FAQ_DETAIL_S = 'FAQ_DETAIL_S'
 export const FAQ_DETAIL_F = 'FAQ_DETAIL_F'

// Sub Admin
export const SUB_ADMIN_LIST_S = 'SUB_ADMIN_LIST_S'
export const SUB_ADMIN_LIST_F = 'SUB_ADMIN_LIST_F'

export const SUB_ADMIN_DETAIL_S = 'SUB_ADMIN_DETAIL_S'
export const SUB_ADMIN_DETAIL_F = 'SUB_ADMIN_DETAIL_F'

// Strategic Partner
export const STRATEGIC_PARTNER_S = 'STRATEGIC_PARTNER_S'
export const STRATEGIC_PARTNERF_F = 'STRATEGIC_PARTNERF_F'

export const STRATEGIC_USER_VIEW_S = 'STRATEGIC_USER_VIEW_S'
export const STRATEGIC_USER_VIEW_F = 'STRATEGIC_USER_VIEW_F'


export const STRATEGIC_POST_LIST_S= 'STRATEGIC_POST_LIST_S'
export const STRATEGIC_POST_LIST_F = 'STRATEGIC_POST_LIST_F'

export const PENDING_STRATEGIC_POST_LIST_S= 'PENDING_STRATEGIC_POST_LIST_S'
export const PENDING_STRATEGIC_POST_LIST_F = 'PENDING_STRATEGIC_POST_LIST_F'

//dashboard
export const DASHBOARD_S = 'DASHBOARD_S'
export const DASHBOARD_F = 'DASHBOARD_F'

export const DASHBOARD_YEARGRAPH_S = 'DASHBOARD_DONOR_YEARGRAPH_S'
export const DASHBOARD_YEARGRAPH_F = 'DASHBOARD_DONOR_YEARGRAPH_F'

export const DASHBOARD_DAYGRAPH_S = 'DASHBOARD_DONOR_DAYGRAPH_S'
export const DASHBOARD_DAYGRAPH_F = 'DASHBOARD_DONOR_DAYGRAPH_F'


//subscribe sonor 
export const SUBSCRIBE_DONOR_LIST_S ='SUBSCRIBE_DONOR_LIST_S'
export const SUBSCRIBE_DONOR_LIST_F = 'SUBSCRIBE_DONOR_LIST_F'
export const SUBSCRIBE_ONETIMEDONOR_LIST_S = 'SUBSCRIBE_ONETIMEDONOR_LIST_S'
export const SUBSCRIBE_ONETIMEDONOR_LIST_F = 'SUBSCRIBE_ONETIMEDONOR_LIST_F'

//Badge Mangement
export const BADGE_LIST_S = 'BADGE_LIST_S'
export const BADGE_LIST_F  = ' BADGE_LIST_F'

export const BADGE_DETAIL_S = 'BADGE_DETAIL_S'
export const BADGE_DETAIL_F = 'BADGE_DETAIL_F'