import { combineReducers } from 'redux';

import api from './api';
import auth from './auth';
import ChairtyReducer from './charity';
import CauseReducer from './cause';
import RegistrationReducer from './Registration'
import CmsReducer from './cms';
import metaReducer from './meta';
import charityCategoryReducer from './charityCategory';
import CommunityReducer from './community'
import AboutReducer from './about'
import EventReducer from './event'
import DonorReducer from './donor'
import PostReducer from './post'
import LeadershipReducer from './leadership'
import chatReducer from './chat';
import TransactionReducer from './transction'
import SubAdminReducer from './subadmin'
import DashboardReducer from './dashboard'
import BadgeReducer from './badgemangement'
import SubscribeDonorReducer from './subscribe'
import StrategicReducer from './strategic-partner'

const rootReducer = combineReducers({
    api,
    auth,
    RegistrationReducer,
    ChairtyReducer,
    CauseReducer,
    CmsReducer,
    metaReducer,
    charityCategoryReducer,
    CommunityReducer,
    AboutReducer,
    EventReducer,
    DonorReducer,
    PostReducer,
    LeadershipReducer,
    chat: chatReducer,
    TransactionReducer,
    SubAdminReducer,
    DashboardReducer,
    BadgeReducer,
    SubscribeDonorReducer,
    StrategicReducer
})

export default rootReducer;