import { STRATEGIC_PARTNERF_F, STRATEGIC_PARTNER_S, STRATEGIC_USER_VIEW_F, STRATEGIC_USER_VIEW_S, SUB_ADMIN_DETAIL_F, SUB_ADMIN_DETAIL_S, SUB_ADMIN_LIST_F, SUB_ADMIN_LIST_S } from "../constants/types";

const initialState = {
    subadminlist:[],
    subadmindetail:{},
    strategicpartnerlist:[],
    strategicUserView:{}
};

const SubAdminReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUB_ADMIN_LIST_S:
            return {
                ...state, subadminlist: action.payload.data
            }
        case SUB_ADMIN_LIST_F:
            return {
                ...state, subadminlist: action.payload.message
            }
        case SUB_ADMIN_DETAIL_S:
            return {
                ...state, subadmindetail: action.payload.data
            }
        case SUB_ADMIN_DETAIL_F:
            return {
                ...state, subadmindetail: action.payload.message
            }
            case STRATEGIC_PARTNER_S:
            return {
                ...state, strategicpartnerlist: action.payload.data
            }
        case STRATEGIC_PARTNERF_F:
            return {
                ...state, strategicpartnerlist: action.payload.message
            }

            case STRATEGIC_USER_VIEW_S:
                return {
                    ...state,
                    strategicUserView: action.payload.data,
                };
            case STRATEGIC_USER_VIEW_F:
                return {
                    ...state,
                    strategicUserView: action.payload.message,
                };
        default:
            return state;
    }
}
export default SubAdminReducer