import {
  API_EVENT_CREATE,
  API_EVENT_DELETE_ID,
  API_EVENT_GETALL,
  API_EVENT_GET_ID,
  API_EVENT_MEMBER_DELETE,
  API_EVENT_MEMBER_LIST,
  API_EVENT_UPDATE_ID,
} from "../constants/api";
import {
  API,
  EVENT_ADD_FAIL,
  EVENT_ADD_SUCCESS,
  EVENT_DETAILS_F,
  EVENT_DETAILS_S,
  EVENT_LISTING_FAIL,
  EVENT_LISTING_SUCCESS,
  EVENT_MEMBER_DELETE_F,
  EVENT_MEMBER_DELETE_S,
  EVENT_UPDATE_FAIL,
  EVENT_UPDATE_SUCCESS,
  GOING_IN_EVENT_F,
  GOING_IN_EVENT_S,
  INTERESTED_IN_EVENT_F,
  INTERESTED_IN_EVENT_S,
  NOTGOING_IN_EVENT_F,
  NOTGOING_IN_EVENT_S,
} from "../constants/types";

export const eventListing = (data) => ({
  type: API,
  payload: {
    url: API_EVENT_GETALL,
    method: "POST",
    data: data,
    success: (data) => ({
      type: EVENT_LISTING_SUCCESS,
      payload: data,
    }),
    error: (data) => ({
      type: EVENT_LISTING_FAIL,
      payload: data,
    }),
  },
});

export const eventDelete = (id) => ({
  type: API,
  payload: {
    url: `${API_EVENT_DELETE_ID}/${id.id}`,
    method: "POST",
    data: id,
  },
});

export const eventEdit = (id, data, type) => ({
  type: API,
  payload: {
    url: API_EVENT_GET_ID + `${id}`,
    method: "POST",
    data: data,
    success: (data) => {
      if (type === 1) {
        return {
          type: INTERESTED_IN_EVENT_S,
          payload: data,
        };
      } else if (type === 2) {
        return {
          type: GOING_IN_EVENT_S,
          payload: data,
        };
      } else if (type === 3) {
        return {
          type: NOTGOING_IN_EVENT_S,
          payload: data,
        };
      } else {
        return {
          type: EVENT_DETAILS_S,
          payload: data,
        };
      }
    },
    error: (data) => {
      if (type === 1) {
        return {
          type: INTERESTED_IN_EVENT_F,
          payload: data,
        };
      } else if (type === 2) {
        return {
          type: GOING_IN_EVENT_F,
          payload: data,
        };
      } else if (type === 3) {
        return {
          type: NOTGOING_IN_EVENT_F,
          payload: data,
        };
      } else {
        return {
          type: EVENT_DETAILS_F,
          payload: data,
        };
      }
    },
  },
});
export const eventUpdate = (id, data) => {
  return {
    type: API,
    payload: {
      url: API_EVENT_UPDATE_ID + `${id}`,
      method: "PATCH",
      data: data,
      success: (data) => ({
        type: EVENT_UPDATE_SUCCESS,
        payload: data,
      }),
      error: (data) => ({
        type: EVENT_UPDATE_FAIL,
        payload: data,
      }),
    },
  };
};
export const eventCreate = (data) => ({
  type: API,
  payload: {
    url: API_EVENT_CREATE,
    method: "POST",
    data: data,
    success: (data) => ({
      type: EVENT_ADD_SUCCESS,
      payload: data,
    }),
    error: (data) => ({
      type: EVENT_ADD_FAIL,
      payload: data,
    }),
  },
});

export const eventMemberListing = (id) => ({
  type: API,
  payload: {
    url: API_EVENT_MEMBER_LIST + `${id}`,
    method: "GET",
  },
});

export const eventMemberDelete = (data) => ({
  type: API,
  payload: {
    url: API_EVENT_MEMBER_DELETE,
    method: "DELETE",
    data: data,
    success: (data) => ({
      type: EVENT_MEMBER_DELETE_S,
      payload: data,
    }),
    error: (data) => ({
      type: EVENT_MEMBER_DELETE_F,
      payload: data,
    }),
  },
});
