import { SubAdminRolesData } from "./subAdminRole";

let permissionArr = [];

export const setContainerArray = () => {
  permissionArr = [];
  SubAdminRolesData.forEach((roleData) => {
    return permissionArr.push({
      title: roleData.role,
      ischilderan: roleData?.ischilderan || false,
      parent: roleData?.parent || false,
      slug: roleData?.to,
      haveAccess: [],
    });
  });
};

export const ManagePermissionRoute = (SuperAdminRoutes, permissions) => {
  let AdminRoutes = [];
  let permission = permissions ? permissions : [];
  permission.forEach((role) => {
    if (!role.ischilderan) {
      let routes = SuperAdminRoutes.find((route) => route.name === role.title);
      if (routes) AdminRoutes.push(routes);
    } else {
      let parentroute = SuperAdminRoutes.find(
        (route) => route.name === role.parent
      );

      let parentInAdminRoutes = AdminRoutes.find((route) => {
        return route?.name === parentroute?.name;
      });
      let subRoutes = parentroute.children.find(
        (route) => route.name === role.title
      );
      if (!parentInAdminRoutes) {
        AdminRoutes.push({ ...parentroute, children: [subRoutes] });
      } else {
        parentroute = {
          ...parentroute,
          children: [...parentInAdminRoutes.children, subRoutes],
        };

        AdminRoutes = AdminRoutes.filter(
          (item) => item !== parentInAdminRoutes
        );
        AdminRoutes.push(parentroute);
      }
    }
  });
  return AdminRoutes;
};

export const RoleBasedModule = (moduleName, permissions) => {
  permissionArr = [];
  let permission = permissions ? permissions : [];

  const modulePermissions = permission.find(
    (role) => role.title === moduleName
  );
  return modulePermissions;
};

export const setInitialPermission = (roles) => {
  roles &&
    permissionArr.map((e, i) => {
      let temp = roles.find((role) => role.title === e.title);
      if (temp && temp.haveAccess) {
        e.haveAccess = temp.haveAccess;
      }
      return e;
    });
};

export const setViewPermissions = (permissions) => {
  let roleData = [];
  let permission = permissions ? permissions : [];
  SubAdminRolesData.forEach((e, i) => {
    let temp = permission.find((role) => role.title === e.role);
    if (temp && temp.haveAccess) {
      e.haveAccess = temp.haveAccess;
      roleData.push(e);
    }
    return e;
  });
  return roleData;
};
export const permissionArray = (e, rowTitle) => {
  permissionArr.forEach((role) => {
    if (role.title === rowTitle) {
      const value = e.target.value;
      if (e.target.checked) {
        role.haveAccess.push(value);
        role.haveAccess = role.haveAccess.filter(
          (value, index, self) => self.indexOf(value) === index
        );
      } else {
        role.haveAccess = role.haveAccess.filter((i) => i !== value);
      }
    }
    return role;
  });
  let finalPermissionArr = permissionArr.filter(
    (role) => role.haveAccess.length > 0
  );
  return finalPermissionArr;
};

export const removeHaveAccessOfMessage = (rowTitle) => {
  permissionArr = permissionArr.map((role) =>
    role.title === rowTitle ? { ...role, haveAccess: [] } : role
  );
};
export const GetDefaultPermissions = (roles, cell, rowTitle) => {
  let isChecked = false;
  let permission = roles.find((role) => role?.title === rowTitle);
  if (permission !== undefined) {
    isChecked = permission.haveAccess.includes(cell);
  }
  return isChecked;
};
