import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "./charitys.scss";
import { Link } from "react-router-dom";
import Delete from "../../../components/svg/delete";
import Edit from "../../../components/svg/edit";
import Plus from "../../../components/svg/plus";
import { useDispatch, useSelector } from "react-redux";
import { CharityManagementListing } from "../../../actions/charity";
import StatusCharityModel from "../../../components/common/charity-manage-status";
import DeleteCharityManageModel from "../../../components/common/charity-manage-delete-modal";
import { debounce, taxIdFormater } from "../../../utils";
import Breadcrumb from "../../../components/layout/Breadcrumb";
import { RoleBasedModule } from "../../SubAdmin/Role";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "../../Registration/tabs.scss";
import "react-tabs/style/react-tabs.css";
import moment from "moment";

const list = [
  {
    name: "Master Management",
    link: "",
    isactive: false,
  },
  {
    name: "Nonprofit Management",
    link: "",
    isactive: true,
  },
];

const customStyles = {
  headCells: {
    style: {
      paddingTop: "8px",
      paddingBottom: "8px",
    },
  },
  cells: {
    style: {
      paddingTop: "8px",
      paddingBottom: "8px",
    },
  },
};
const TITLE = "Nonprofit Management";
const InCharitys = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth?.user?.data);
  const charityNewList = useSelector(
    (state) => state.ChairtyReducer.charityNewList.charityList
  );

  const [arg, setArg] = useState({
    page: 1,
    limit: 10,
    column: "",
    order: "",
    isNavigator: false,
  });

  const [charityStatusModel, setCharityStatusModel] = useState(false);
  const [isCharityActive, setIsCharityActive] = useState(false);
  const [charityManageDeleteModel, setCharityManageDeleteModel] =
    useState(false);

  const [charityModelId, setCharityModelId] = useState();
  const [charityManageDeleteId, setCharityManageDeleteId] = useState();
  const [totalRecords, settotalRecords] = useState(0);
  const [Role, setRoles] = useState([]);
  const handlerDelete = (id) => {
    setCharityManageDeleteId(id);
    setCharityManageDeleteModel(true);
  };

  const onHnadlerActive = (charityDetail) => {
    setCharityModelId(charityDetail._id);
    setCharityStatusModel((prev) => !prev);
    setIsCharityActive(charityDetail?.isActive);
  };

  useEffect(() => {
    let haveAccess =
      userData &&
      userData?.adminType === "Sub Admin" &&
      RoleBasedModule(TITLE, userData.permissions);
    haveAccess
      ? setRoles(haveAccess.haveAccess)
      : setRoles(["add", "edit", "delete", "view"]);
  }, [userData]);

  useEffect(() => {
    dispatch(CharityManagementListing(arg)).then((res) => {
      settotalRecords(res?.data?.total_records || 0);
    });
  }, [arg]);

  const columns = [
    {
      name: "Nonprofit Name",
      sortName: "charityName",
      selector: (row) => row.charityName,
      sortable: true,
    },
    {
      name: "Tax ID",
      selector: (row) => taxIdFormater(row.taxId),
    },
    {
      name: "Admin Approve at ",
      sortName: "adminApproveAt",
      // selector: (row) =>
      //   moment(row?.adminApproveAt).format("HH:mm:ss MM/DD/YYYY"),
      selector: (row) => moment(row.adminApproveAt).format("MMM D YYYY,h:mm a"),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (roww) => (
        <div className="status-box ">
          {Role.includes("edit") || Role.includes("add") ? (
            <label className="switch">
              <input
                type="checkbox"
                checked={roww.isActive}
                onChange={() => onHnadlerActive(roww)}
              />
              <span className="slider rounds"></span>
            </label>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => row.action,
      minWidth: "180px",
      cell: (row) => (
        <div className="action-box">
          {Role.includes("delete") && (
            <span
              className="btn wrong"
              title="delete"
              onClick={() => handlerDelete(row._id)}
            >
              {" "}
              <Delete />{" "}
            </span>
          )}
          {Role.includes("edit") && (
            <Link
              to={`/master/charity_edit/${row._id}`}
              className="btn right"
              title="edit"
            >
              {" "}
              <Edit />{" "}
            </Link>
          )}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const onChangeFun = (e) => {
    if (e?.length > 2) {
      setArg({ ...arg, search: e });
    } else if (e.length === 0) {
      setArg({ ...arg, search: "" });
    }
  };
  const optimizedFn = debounce(onChangeFun);

  const handlePerRowsChange = async (newPerPage, page) => {
    setArg({ ...arg, limit: newPerPage });
  };

  const handlePageChange = (page) => {
    setArg({ ...arg, page: page });
  };
  const onSortChange = (column, direction) => {
    setArg({ ...arg, column: column?.sortName, order: direction });
  };

  return (
    <>
      <div className="charitys-section">
        <div className="top-box">
          <Breadcrumb list={list} />
          <h2>Nonprofit Management </h2>
        </div>
        <div className="section-inner">
          <div className="d-flex justify-content-between">
            <div className="search-box">
              <input
                type="text"
                placeholder="Search by Nonprofit Name"
                className="search-input"
                onChange={(e) => optimizedFn(e.target.value)}
              />
            </div>
            <div className="image-box">
              <Tabs>
                <TabList>
                  <Tab
                    onClick={() => {
                      setArg({ ...arg, isNavigator: false });
                    }}
                  >
                    Onboarded Nonprofit
                  </Tab>
                  {Role.includes("delete") && (
                    <Tab
                      onClick={() => {
                        setArg({ ...arg, isNavigator: true });
                      }}
                    >
                      Imported Nonprofit
                    </Tab>
                  )}
                </TabList>
                <TabPanel></TabPanel>
                {Role.includes("delete") && <TabPanel></TabPanel>}
              </Tabs>
            </div>
            {Role.includes("add") && (
              <div className="div-btn-box">
                <Link to="/master/charity_add" className="btn" title="add">
                  {" "}
                  <Plus /> Add New Nonprofit{" "}
                </Link>
              </div>
            )}
          </div>
          <DataTable
            border
            columns={columns}
            data={charityNewList}
            onSort={onSortChange}
            pagination
            sortServer
            paginationServer
            paginationTotalRows={totalRecords}
            onChangeRowsPerPage={handlePerRowsChange}
            customStyles={customStyles}
            onChangePage={handlePageChange}
            dense
          />
        </div>
      </div>
      <StatusCharityModel
        setCharityStatusModel={setCharityStatusModel}
        charityStatusModel={charityStatusModel}
        charityModelId={charityModelId}
        isCharityActive={isCharityActive}
      />
      <DeleteCharityManageModel
        setCharityManageDeleteModel={setCharityManageDeleteModel}
        charityManageDeleteModel={charityManageDeleteModel}
        charityManageDeleteId={charityManageDeleteId}
      />
    </>
  );
};

export default InCharitys;
