import {
  EVENT_LISTING_SUCCESS,
  EVENT_LISTING_FAIL,
  EVENT_ADD_SUCCESS,
  EVENT_ADD_FAIL,
  EVENT_UPDATE_SUCCESS,
  EVENT_UPDATE_FAIL,
  EVENT_DETAILS_S,
  EVENT_DETAILS_F,
  INTERESTED_IN_EVENT_S,
  INTERESTED_IN_EVENT_F,
  GOING_IN_EVENT_S,
  GOING_IN_EVENT_F,
  NOTGOING_IN_EVENT_S,
  NOTGOING_IN_EVENT_F,
} from "../constants/types";
const initialState = {
  eventList: {},
  cause: {},
  addEvent: {},
  editEvent: {},
  updateEvent: {},
};
const EventReducer = (state = initialState, action) => {
  switch (action.type) {
    case EVENT_LISTING_SUCCESS:
      return {
        ...state,
        eventList: action.payload.data,
      };
    case EVENT_LISTING_FAIL:
      return {
        ...state,
        eventList: action.payload.message,
      };

    case EVENT_ADD_SUCCESS:
      return {
        ...state,
        addEvent: action.payload.data,
      };
    case EVENT_ADD_FAIL:
      return {
        ...state,
        addEvent: action.payload.message,
      };

    //EDIT event
    // case EVENT_EDIT_SUCCESS:
    //     return {
    //         ...state,
    //         editEvent: action.payload.data,
    //     };
    // case EVENT_EDIT_FAIL:
    //     return {
    //         ...state,
    //         editEvent: action.payload.data,
    //     };
    case EVENT_DETAILS_S:
      return {
        ...state,
        eventdetails: action.payload.data,
      };
    case EVENT_DETAILS_F:
      return {
        ...state,
        eventdetails: action.payload.message,
      };
    case INTERESTED_IN_EVENT_S:
      return {
        ...state,
        interested: action.payload.data,
      };
    case INTERESTED_IN_EVENT_F:
      return {
        ...state,
        interested: action.payload.message,
      };
    case GOING_IN_EVENT_S:
      return {
        ...state,
        going: action.payload.data,
      };
    case GOING_IN_EVENT_F:
      return {
        ...state,
        going: action.payload.message,
      };
    case NOTGOING_IN_EVENT_S:
      return {
        ...state,
        notGoing: action.payload.data,
      };
    case NOTGOING_IN_EVENT_F:
      return {
        ...state,
        notGoing: action.payload.message,
      };

    //EDIT event
    case EVENT_UPDATE_SUCCESS:
      return {
        ...state,
        updateEvent: action.payload,
      };
    case EVENT_UPDATE_FAIL:
      return {
        ...state,
        updateEvent: action.payload.message,
      };

    default:
      return state;
  }
};

export default EventReducer;
