import { API_SERVICE_CHARGES_ADD, API_SERVICE_CHARGES_VIEW } from "../constants/api";
import { API } from "../constants/types";

export const servicechargesAction = (id,data) => ({
    type: API,
    payload: {
      url: API_SERVICE_CHARGES_ADD +id,
      method: "POST",
      data: data,
    },
  });

  export const servicechargesViewAction = () => ({
    type: API,
    payload: {
      url: API_SERVICE_CHARGES_VIEW,
      method: "GET",
    //   data: data,
    },
  });