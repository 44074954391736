import { API_APPROVE_REJECT_STRATEGIC_PARTNER, API_DELETE_STRATEGIC_PARTNER, API_PENDING_STRATEGIC_LIST } from "../constants/api";
import { API, PENDING_STRATEGIC_POST_LIST_F, PENDING_STRATEGIC_POST_LIST_S } from "../constants/types";

export const strategicPartnerlist = (data) =>({
    type: API,
    payload: {
        url: API_PENDING_STRATEGIC_LIST,
        method: 'POST',
        data : data,
        success: (data) => ({
            type: PENDING_STRATEGIC_POST_LIST_S,
            payload: data
        }),
        error: (data) => ({
            type: PENDING_STRATEGIC_POST_LIST_F,
            payload: data
        })
    }
})


export const strategicPartnerAprroveReject = (data) =>({
    type: API,
    payload: {
        url: API_APPROVE_REJECT_STRATEGIC_PARTNER,
        method: 'POST',
        data : data,
    }
})

export const strategicPartnerDelete  = (data)=>({
    type: API,
    payload: {
        url: API_DELETE_STRATEGIC_PARTNER,
        method: 'POST',
        data : data,
    }
})