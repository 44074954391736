import React from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { adminChangePassword } from "../../actions/auth";
import { renderField } from "../../components/forms";
import { toAbsoluteUrl } from "../../utils";
import "./ChangePassword.scss";
import { changePasswordValidation as validate } from "../../components/validations/signIn";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Change = useSelector((state) => state.auth?.user?.data);

  const onSubmit = (value) => {
    const data = {
      id: Change?._id,
      currentPassword: value.OldPassword,
      newPassword: value.Newpassword,
    };

    dispatch(adminChangePassword(data))
      .then((res) => {
        navigate("/signin");
        toast.success(res.message);
      })
      .catch((res) => toast.error(res.message));
  };

  return (
    <>
      <section className="section-div pwd-section">
        <div className="d-flex align-items-center min-h100">
          <div className="col-6 none-mobile">
            <div>
              <figure className="text-center figure-img">
                <img
                  src={toAbsoluteUrl("/images/signIn.png")}
                  alt="left-img"
                />
              </figure>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <Form onSubmit={onSubmit} validate={validate}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="form-box">
                  <figure className="mainLogo">
                    <img src={toAbsoluteUrl("/images/logo.svg")} alt="logo" />
                  </figure>
                  <span className="signHeader">Rosi Giving</span>
                  <ul className="input-list">
                    <li>
                      <Field
                        name="OldPassword"
                        type="password"
                        placeholder="Old Password"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                      />
                    </li>
                    <li>
                      <Field
                        name="Newpassword"
                        type="password"
                        placeholder="New password"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                      />
                    </li>
                    <li>
                      <Field
                        name="Confirmpassword"
                        type="password"
                        placeholder="Confirm password"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                      />
                    </li>
                  </ul>
                  <div>
                    <button type="submit" className="link-btn">
                      Change Password
                    </button>
                  </div>
                </form>
              )}
            </Form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChangePassword;
