import React, { Suspense } from 'react';
import axios from 'axios';
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import store from '../store';
import Routes from './routes';
import ScrollToTop from '../components/common/scroll';
import Loader from '../components/common/loader';
import { setupAxios } from '../utils';
import { ErrorBoundary } from "../components/Error";
import { ToastContainer } from 'react-toastify';
import 'video-react/styles/scss/video-react.scss';
import 'react-toastify/dist/ReactToastify.css';

const { PUBLIC_URL } = process.env;

setupAxios(axios, store);

const AppContainer = () => {
    return (
        <Provider store={store} >
            <ToastContainer />
            <Suspense fallback={<Loader isSuspense />}>
                <Loader>
                    <ErrorBoundary >
                        <BrowserRouter basename={PUBLIC_URL}>
                            <ScrollToTop>
                                <Routes />
                            </ScrollToTop>
                        </BrowserRouter>
                    </ErrorBoundary>
                </Loader>
            </Suspense>
        </Provider >)
}

export default AppContainer;