// import React, { Component, useState } from "react";
// import AsyncSelect from "react-select/async";
// import { FixedSizeList as List } from "react-window";
// import { createFilter } from "react-select";
// import { useDispatch } from "react-redux";
// import { charityCauseList } from "../../actions/notification";

// const SelectTest = (vendorOptions) => {
//   const [loadedOptions, setLoadedOptions] = useState([
//     {
//       label: "WAYNE COUNTY IMPROVEMENT CORP",
//       value: "640571ba2b4860554bcc943a",
//     },
//     {
//       label: "MONROE LAKESIDE FIRE AND RESCUE COMPANY INC",
//       value: "640571ba2b4860554bcc94c6",
//     },
//     {
//       label: "MOBILE MEDICAL ENDOWMENT TRUST INC",
//       value: "640571ba2b4860554bcc94ca",
//     },
//     {
//       label: "FRIENDS OF THE NATIONAL ZOO",
//       value: "640571ba2b4860554bcc94d2",
//     },
//     {
//       label: "WAKULLA SENIOR CITIZENS COUNCIL INC",
//       value: "640571ba2b4860554bcc94d6",
//     },
//     {
//       label: "ST LUKE HOSPITAL FOUNDATION",
//       value: "640571ba2b4860554bcc943e",
//     },
//     {
//       label: "EAST HARTFORD COMMUNITY TELEVISION INC",
//       value: "640571ba2b4860554bcc9436",
//     },
//     {
//       label: "PENCE GALLERY ASSOCIATION",
//       value: "640571ba2b4860554bcc94e2",
//     },
//     {
//       label: "OPPORTUNITIES INC",
//       value: "640571ba2b4860554bcc94e6",
//     },
//     {
//       label: "GOVERNORS SCHOOL FOR THE ARTS FDN INC",
//       value: "640571ba2b4860554bcc94f6",
//     },
//   ]);
//   console.log("loadedOptions: ", loadedOptions);
//   const dispatch = useDispatch();

//   // const options = [{ value: "ABC", label: "ABC" }];
//   //   for (let i = 0; i < vendorOptions["vendorOptions"]?.length; i = i + 1) {
//   //     options.push({
//   //       value: vendorOptions["vendorOptions"][i],
//   //       label: `${vendorOptions["vendorOptions"][i]}`,
//   //     });
//   //   }

//   const loadOptions = (inputValue, callback) => {
//     const payload = {
//       page: 1,
//       limit: 10,
//       type: "charity",
//     };
//     dispatch(charityCauseList(payload)).then((res) => {
//       console.log("res: ", res);
//       const list = res?.data?.list?.map((charity) => {
//         return {
//           label: charity?.name,
//           value: charity?._id,
//         };
//       });
//       console.log("list: ", list);
//       //   setLoadedOptions([...loadedOptions, ...list]);
//       callback([...loadedOptions, ...list]);
//     });
//   };

//   const height = 35;

//   class MenuList extends Component {
//     render() {
//       const { options, children, maxHeight, getValue } = this.props;
//       const [value] = getValue();
//       const initialOffset = options.indexOf(value) * height;

//       return (
//         <List
//           height={maxHeight}
//           itemCount={children.length}
//           itemSize={height}
//           initialScrollOffset={initialOffset}
//         >
//           {({ index, style }) => <div style={style}>{children[index]}</div>}
//         </List>
//       );
//     }
//   }

//   const TestSelectComponent = () => {
//     return (
//       <div className="testDropdown">
//         <AsyncSelect
//           components={{ MenuList }}
//           cacheOptions
//           defaultOptions
//           loadOptions={loadOptions}
//           filterOption={createFilter({ ignoreAccents: false })}
//           onMenuScrollToBottom={() => console.log("=====>")}
//         />
//       </div>
//     );
//   };
//   return <TestSelectComponent />;
// };
// export default SelectTest;

import { useState } from "react";
import { useDispatch } from "react-redux";

import { AsyncPaginate } from "react-select-async-paginate";
import { charityCauseList } from "../../actions/notification";

import { loadOptions } from "./loadOptions";

function SelectTest() {
  const [loadedOptions, setLoadedOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const dispatch = useDispatch();

  const loadOptions = async (search, prevOptions) => {
    const payload = {
      page,
      limit,
      type: "charity",
    };

    const res = await dispatch(charityCauseList(payload));

    const list = res?.data?.list?.map((charity) => {
      return {
        label: charity?.name,
        value: charity?._id,
      };
    });

    setPage(page + 1);

    return {
      options: list,
      hasMore: true,
    };
  };

  return (
    <div>
      <h1>react-select-async-paginate</h1>
      <h2>Multi example</h2>

      <AsyncPaginate
        value={loadedOptions}
        loadOptions={loadOptions}
        isMulti
        closeMenuOnSelect={false}
        onChange={setLoadedOptions}
      />
    </div>
  );
}

export default SelectTest;
