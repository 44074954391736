import { BADGE_DETAIL_F, BADGE_DETAIL_S, BADGE_LIST_F, BADGE_LIST_S } from "../constants/types";

const initialState = {
    badgelist: [],
    badgedetail:{}
};

const BadgeReducer = (state = initialState, action) => {
    switch (action.type) {
        case BADGE_LIST_S:
            return {
                ...state, badgelist: action.payload.data
            }
        case BADGE_LIST_F:
            return {
                ...state, badgelist: action.payload.message
            }
        case BADGE_DETAIL_S:
            return {
                ...state, badgedetail: action.payload.data
            }
        case BADGE_DETAIL_F:
            return {
                ...state, badgedetail: action.payload.message
            }
        default:
            return state;
    }
}
export default BadgeReducer