import patterns from "../../utils/patterns"

export const login = value => {
  let error = {};

  if (!value.email) { error.email = 'Email is required' }
  else if (!/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i.test(value.email)) {
    error.email = "Please enter valid email address."
  }
  if (!value.password || !value.password.trim()) { error.password = 'Password is required' }
  else if (value.password && !/^[^\s]+(\s+[^\s]+)*$/.test(value.password)) {
    error.password = "Password must not contain while spaces"
}
  return error;
}
export const changePasswordValidation = (value) => {
  let error = {}
  if (!value.OldPassword) {
    error.OldPassword = 'Old Password is Required'
  } else if (value.OldPassword.length < 6 || value.OldPassword.length > 20) {
    error.OldPassword = 'Old Pasword  length  is must be  6 to 20'
  } else if (value.OldPassword && !/^[^\s]+(\s+[^\s]+)*$/.test(value.OldPassword)) {
    error.OldPassword = "OldPassword must not contain while spaces"
  }

  if (!value.Newpassword) {
    error.Newpassword = 'New Password is Required'
  } else if (value.Newpassword.length < 6 || value.Newpassword.length > 20) {
    error.Newpassword = 'Password  length  is must be  6 to 20'
  } else if (value.Newpassword && !/^[^\s]+(\s+[^\s]+)*$/.test(value.Newpassword)) {
    error.Newpassword = "Newpassword must not contain while spaces"
  }else if (value.OldPassword === value.Newpassword){
    error.Newpassword = 'Old Password and New Password can not be same'
  }

  if (!value.Confirmpassword) {
    error.Confirmpassword = 'Confirm Password is Required'
  } else if (value.Confirmpassword.length < 6 || value.Confirmpassword.length > 20) {
    error.Confirmpassword = 'Confirm Password  length  is must be  6 to 20'
  } else if (value.Confirmpassword && !/^[^\s]+(\s+[^\s]+)*$/.test(value.Confirmpassword)) {
    error.Confirmpassword = "Confirmpassword must not contain while spaces"
  } else if (value.Newpassword !== value.Confirmpassword) {
    error.Confirmpassword = "Confirm Password must match with New Password";
  }

  return error;
}
export const resetPasswordValidation = (value) => {
  let error = {};
  if (!value.Newpassword || !value.Newpassword.trim()) {
    error.Newpassword = "New Password is required";
  }
  if (!value.Confirmpassword || !value.Newpassword.trim()) {
    error.Confirmpassword = "Confirm Password is required";
  }
  else if (value.Newpassword !== value.Confirmpassword) {
    error.Confirmpassword = "Confirm Password must match with New password";
  }
  return error;
};
export const registrationstep1 = (value) => {
  let error = {};
  if (!value.UserName || !value.UserName.trim()) {
    error.UserName = "Please enter User Name";
  }
  if (!value.CharityName || !value.CharityName.trim()) {
    error.CharityName = "Please enter Charity Name";
  }
  if (!value.CharityPersonName || !value.CharityPersonName.trim()) {
    error.CharityPersonName = "Please enter Charity Person Name";
  }
  if (!value.CharityEmailAddress || !value.CharityEmailAddress.trim()) {
    error.CharityEmailAddress = "Please enter Charity Email Address";
  } else if (
    value.CharityEmailAddress &&
    !/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i.test(
      value.CharityEmailAddress
    )
  ) {
    error.CharityEmailAddress = "Please enter valid Charity Email Address";
  }

  if (!value.Password) {
    error.Password = "Password is required";
  }

  if (!value.ConfirmPassword) {
    error.ConfirmPassword = "Confirm Password is required";
  } else if (value.Password !== value.ConfirmPassword) {
    error.ConfirmPassword = "Confirm Password must match with Password";
  }
  return error;
};

export const registrationstep2 = (value) => {
  let error = {};
  if (!value.CharityDescription || !value.CharityDescription.trim()) {
    error.CharityDescription = "Please enter Charity Description";
  } else if (value.CharityDescription.length > 250) {
    error.CharityDescription = "CharityDescription character shoud be 250";
  }
  if (!value.CharityBriefDescription || !value.CharityBriefDescription.trim()) {
    error.CharityBriefDescription = "Please enter Charity Brief Description";
  } else if (value.CharityBriefDescription.length > 150) {
    error.CharityBriefDescription =
      "Charity Brief Description character shoud be 150";
  }
  if (!value.PhoneNumber || !value.PhoneNumber.trim()) {
    error.PhoneNumber = "Please enter Phone Number";
  }
  if (!value.CharityAddress || !value.CharityAddress.trim()) {
    error.CharityAddress = "Please enter Charity Address";
  }
  if (!value.country) {
    error.country = "Please enter country";
  }
  if (!value.state) {
    error.state = "Please enter state";
  }
  if (!value.city ) {
    error.city = "Please enter city";
  } 
  
  if (!value.pincode || !value.pincode.trim()) {
    error.pincode = "Please enter Zipcode";
  }
  else if (!patterns.numeric.test(value.pincode)) {
    error.pincode = "Please enter valid Zipcode";
  }else if (value.pincode.length >5){
    error.pincode = " Zipcode shoud be 5 digit ";
  }
  return error;
};

export const registrationstep3 = (value) => {

  let error = {}
  if (!value.CharityWebsite || !value.CharityWebsite.trim()) {
    error.CharityWebsite = "Please enter Charity Website";
  }else if (value.CharityWebsite && !/^(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/.test(value.CharityWebsite)) {
    error.CharityWebsite = 'Enter valid Charity Website'
}

  if (!value.CharityFilter || !value.CharityFilter.trim()) {
    error.CharityFilter = "Please enter Charity Filter";
  }
  if (!value.CharityContribution || !value.CharityContribution.trim()) {
    error.CharityContribution = "Please enter Charity Contribution";
  }else if (!patterns.numeric.test(value.CharityContribution)) {
    error.CharityContribution = "Please enter valid Amount";
  }
  if (!value.TaxId || !value.TaxId.trim()) {
    error.TaxId = "Please enter TaxId";
  } else if (value.TaxId.length !== 9) {
    error.TaxId = "Tax ID should be 9 digit code";
  }
  if (!value.CharityFormedyear) {
    error.CharityFormedyear = "Please enter Charity Formed year";
  } else if (value.CharityFormedyear > 4) {
    error.CharityFormedyear = "Please enter valid Formed year";
  }
  return error;
}
export const registrationstep4 = (value) => {
  let error = {}
  if (value.radio) {
    error.radio = "Please enter radio";
  }

} 