import React from 'react'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { charityActiveInActive, CharityManagementListing } from '../../../actions/charity'
import './delete.scss'

const StatusCharityModel = ({ setCharityStatusModel, charityStatusModel, charityModelId, isCharityActive }) => {
    const dispatch = useDispatch()
      const arg ={
        page: 1,
        limit: 999999,
        search: "",
    }

    const onSubmit = () => {
        setCharityStatusModel(false)
        dispatch(charityActiveInActive(charityModelId)).then(() => dispatch(CharityManagementListing(arg)))
    }

    return (
        <>
            <div className={`model-area ${charityStatusModel ? "open-model" : ""}`} >
                <div className="model-box" >
                    <div className='modele-text-box' >
                        <Form
                            onSubmit={onSubmit}
                        >
                            {({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    { isCharityActive === true ?
                                        <div>
                                            <h2>Are you sure Inactive this status ?</h2>
                                            <div className='model-btn'>
                                                <button type='submit' className='btn-box' >Yes</button>
                                                <button type='button' className='btn-box no' onClick={() => setCharityStatusModel(false)} >No</button>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <h2>Are you sure Active this status ?</h2>
                                            <div className='model-btn'>
                                                <button type='submit' className='btn-box' >Yes</button>
                                                <button type='button' className='btn-box no' onClick={() => setCharityStatusModel(false)} >No</button>
                                            </div>
                                        </div>
                                    }
                                </form>

                            )}
                        </Form>
                    </div>

                </div>
            </div>
        </>
    )
}

export default StatusCharityModel