import { API_STRATEGIC_PARTNER_DETAIL, API_STRATEGIC_PARTNER_LIST, API_STRATEGIC_PARTNER_STATUS, API_SUB_ADMIN_ADD, API_SUB_ADMIN_DELETE, API_SUB_ADMIN_DETAIL, API_SUB_ADMIN_LIST, API_SUB_ADMIN_STATUS, API_SUB_ADMIN_UPDATE } from "../constants/api";
import { API, STRATEGIC_PARTNERF_F, STRATEGIC_PARTNER_S, STRATEGIC_USER_VIEW_F, STRATEGIC_USER_VIEW_S, SUB_ADMIN_DETAIL_F, SUB_ADMIN_DETAIL_S, SUB_ADMIN_LIST_F, SUB_ADMIN_LIST_S } from "../constants/types";

export const subAdminAdd = (data) =>({
    type: API,
    payload: {
        url: API_SUB_ADMIN_ADD,
        method: 'POST',
        data : data,
         }
})
export const subAdminUpdate = (id,data) =>({
    type: API,
    payload: {
        url: API_SUB_ADMIN_UPDATE +id,
        method: 'POST',
        data : data,
        }
})

export const subAdminDelete = (id) =>({
    type: API,
    payload: {
        url: API_SUB_ADMIN_DELETE + id ,
        method: 'DELETE',
        }
})

export const subAdminsStatus= (id) =>({
    type: API,
    payload: {
        url: API_SUB_ADMIN_STATUS + id,
        method: 'POST',
       }
})

export const subAdminList = (data) =>({
    type: API,
    payload: {
        url: API_SUB_ADMIN_LIST,
        method: 'POST',
        data : data,
        success: (data) => ({
            type: SUB_ADMIN_LIST_S,
            payload: data
        }),
        error: (data) => ({
            type: SUB_ADMIN_LIST_F,
            payload: data
        })
    }
})

export const subAdminDetail= (id) =>({
    type: API,
    payload: {
        url: API_SUB_ADMIN_DETAIL +id ,
        method: 'GET',
        success: (data) => ({
            type: SUB_ADMIN_DETAIL_S,
            payload: data
        }),
        error: (data) => ({
            type: SUB_ADMIN_DETAIL_F,
            payload: data
        })
    }
})

export const strategicApprovePartnerlist = (data) =>({
    type: API,
    payload: {
        url: API_STRATEGIC_PARTNER_LIST,
        method: 'POST',
        data : data,
        success: (data) => ({
            type: STRATEGIC_PARTNER_S,
            payload: data
        }),
        error: (data) => ({
            type: STRATEGIC_PARTNERF_F,
            payload: data
        })
    }
})

export const strategicPartnerstatus = (id) =>({
    type: API,
    payload: {
        url: API_STRATEGIC_PARTNER_STATUS + id,
        method: 'POST',
        }
})

export const strategicUserView = (id) =>({
    type: API,
    payload: {
        url: API_STRATEGIC_PARTNER_DETAIL+ `${id}`,
        method: 'GET',
        data:id,
        success: (data) => ({
            type: STRATEGIC_USER_VIEW_S,
            payload: data
        }),
        error: (data) => ({
            type:STRATEGIC_USER_VIEW_F,
            payload: data
        })
    }
})