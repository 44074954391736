import {
  API_POST_ACTIVE_INACTIVE,
  API_POST_ADD,
  API_POST_COMMENT,
  API_POST_COMMENT_LIKE,
  API_POST_COMMENT_LIST,
  API_POST_COMMENT_REPLY,
  API_POST_DELETE,
  API_POST_HIDE,
  API_POST_INACTIVE_LIST,
  API_POST_LIKE_UNLIKE,
  API_POST_List,
  API_POST_UPATE,
  API_REPORT_POST,
  API_REPORT_POST_DELETE,
  API_STRATEGIC_POST_LIST,
} from "../constants/api";
import {
  API,
  POST_ADD_F,
  POST_ADD_S,
  POST_COMMENT_LIST_F,
  POST_COMMENT_LIST_S,
  POST_DELETE_F,
  POST_DELETE_S,
  POST_HIDE_F,
  POST_HIDE_S,
  POST_LIST_F,
  POST_LIST_S,
  POST_UPDATE_S,
  REPORT_POST_LIST_F,
  REPORT_POST_LIST_S,
  STRATEGIC_POST_LIST_F,
  STRATEGIC_POST_LIST_S,
} from "../constants/types";

export const postAdd = (data) => ({
  type: API,
  payload: {
    url: API_POST_ADD,
    method: "POST",
    data: data,
    success: (data) => ({
      type: POST_ADD_S,
      payload: data,
    }),
    error: (data) => ({
      type: POST_ADD_F,
      payload: data,
    }),
  },
});

export const postList = (id, data, isMyPost) => ({
  type: API,
  payload: {
    url: API_POST_List + id + "/" + isMyPost,
    method: "POST",
    data: data,
    success: (data) => ({
      type: POST_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: POST_LIST_F,
      payload: data,
    }),
  },
});

export const postUpdate = (id, data) => ({
  type: API,
  payload: {
    url: API_POST_UPATE + `${id}`,
    method: "PATCH",
    data: data,
    success: (data) => ({
      type: POST_UPDATE_S,
      payload: data,
    }),
    error: (data) => ({
      type: POST_ADD_F,
      payload: data,
    }),
  },
});

export const postDelete = (id) => ({
  type: API,
  payload: {
    url: API_POST_DELETE + `${id}`,
    method: "DELETE",
    // data: data,
    success: (data) => ({
      type: POST_DELETE_S,
      payload: data,
    }),
    error: (data) => ({
      type: POST_DELETE_F,
      payload: data,
    }),
  },
});

export const postHide = (data) => ({
  type: API,
  payload: {
    url: API_POST_HIDE,
    method: "POSt",
    data: data,
    success: (data) => ({
      type: POST_HIDE_S,
      payload: data,
    }),
    error: (data) => ({
      type: POST_HIDE_F,
      payload: data,
    }),
  },
});

export const postLike = (id, data) => ({
  type: API,
  payload: {
    url: API_POST_LIKE_UNLIKE + id + "/" + data,
    method: "PATCH",
  },
});

export const postCommment = (data) => ({
  type: API,
  payload: {
    url: API_POST_COMMENT,
    method: "POST",
    data: data,
  },
});
export const onPostCommentLikeUnlike = (id) => ({
  type: API,
  payload: {
    url: API_POST_COMMENT_LIKE + id,
    method: "PATCH",
  },
});

export const postCommmentReply = (data) => ({
  type: API,
  payload: {
    url: API_POST_COMMENT_REPLY,
    method: "POST",
    data: data,
  },
});

export const postCommmentList = (id, data) => ({
  type: API,
  payload: {
    url: API_POST_COMMENT_LIST + id,
    method: "POST",
    data: data,
    success: (data) => ({
      type: POST_COMMENT_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: POST_COMMENT_LIST_F,
      payload: data,
    }),
  },
});

export const postActiveInActive = (id) => ({
  type: API,
  payload: {
    url: API_POST_ACTIVE_INACTIVE + id,
    method: "POST",
  },
});

export const postActiveInActivelist = (id, data) => ({
  type: API,
  payload: {
    url: API_POST_INACTIVE_LIST + id,
    method: "POST",
    data: data,
    success: (data) => ({
      type: POST_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: POST_LIST_F,
      payload: data,
    }),
  },
});

export const strategicPostList = (id, data, isMyPost) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_POST_LIST + id + "/" + isMyPost,
    method: "POST",
    data: data,
    success: (data) => ({
      type: STRATEGIC_POST_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: STRATEGIC_POST_LIST_F,
      payload: data,
    }),
  },
});

export const ReportPostList = (data) => ({
  type: API,
  payload: {
    url: API_REPORT_POST,
    method: "POST",
    data: data,
    success: (data) => ({
      type: REPORT_POST_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: REPORT_POST_LIST_F,
      payload: data,
    }),
  },
});

export const ReportPostDelete = (id) => ({
  type: API,
  payload: {
    url: API_REPORT_POST_DELETE + id,
    method: "GET",
  },
});
