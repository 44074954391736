import React from 'react'

const Wrong = () => {
  return (
    <>
      <svg id="svg6" clipRule="evenodd" fillRule="evenodd" height="512" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
        <path id="path2" d="m12 10.586 7.293-7.293c.39-.39 1.024-.39 1.414 0s.39 1.024 0 1.414l-7.293 7.293 7.293 7.293c.39.39.39 1.024 0 1.414s-1.024.39-1.414 0l-7.293-7.293-7.293 7.293c-.39.39-1.024.39-1.414 0s-.39-1.024 0-1.414l7.293-7.293-7.293-7.293c-.39-.39-.39-1.024 0-1.414s1.024-.39 1.414 0z"/>
      </svg>
    </>
  )
}

export default Wrong
