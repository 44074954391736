import {
  API_LEADERSHIP_CREATE,
  API_LEADERSHIP_DELETE_ID,
  API_LEADERSHIP_GET_ID,
  API_LEADERSHIP_UPDATE_ID,
  API_LEADERSHIP_GETALL,
} from "../constants/api";
import {
  API,
  LEADERSHIP_ADD_FAIL,
  LEADERSHIP_ADD_SUCCESS,
  LEADERSHIP_EDIT_FAIL,
  LEADERSHIP_EDIT_SUCCESS,
  LEADERSHIP_LISTING_FAIL,
  LEADERSHIP_LISTING_SUCCESS,
  LEADERSHIP_UPDATE_FAIL,
  LEADERSHIP_UPDATE_SUCCESS,
} from "../constants/types";

export const leadershipListing = (data) => ({
  type: API,
  payload: {
    url: API_LEADERSHIP_GETALL,
    method: "POST",
    data: data,
    success: (data) => ({
      type: LEADERSHIP_LISTING_SUCCESS,
      payload: data,
    }),
    error: (data) => ({
      type: LEADERSHIP_LISTING_FAIL,
      payload: data,
    }),
  },
});

export const leadershipDelete = (id) => ({
  type: API,
  payload: {
    url: `${API_LEADERSHIP_DELETE_ID}/${id}`,
    method: "DELETE",
  },
});

export const leadershipEdit = (id, data) => ({
  type: API,
  payload: {
    url: API_LEADERSHIP_GET_ID + `${id}`,
    method: "GET",
    data: data,
    success: (data) => ({
      type: LEADERSHIP_EDIT_SUCCESS,
      payload: data,
    }),
    error: (data) => ({
      type: LEADERSHIP_EDIT_FAIL,
      payload: data,
    }),
  },
});

export const leadershipUpdate = (data) => {
  return {
    type: API,
    payload: {
      url: API_LEADERSHIP_UPDATE_ID,
      method: "POST",
      data: data,
      success: (data) => ({
        type: LEADERSHIP_UPDATE_SUCCESS,
        payload: data,
      }),
      error: (data) => ({
        type: LEADERSHIP_UPDATE_FAIL,
        payload: data,
      }),
    },
  };
};
export const leadershipCreate = (data) => ({
  type: API,
  payload: {
    url: API_LEADERSHIP_CREATE,
    method: "POST",
    data: data,
    success: (data) => ({
      type: LEADERSHIP_ADD_SUCCESS,
      payload: data,
    }),
    error: (data) => ({
      type: LEADERSHIP_ADD_FAIL,
      payload: data,
    }),
  },
});
