import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  let isLoggedIn = false;

  const token = JSON.parse(localStorage.getItem("user"));
  if (token) {
    isLoggedIn = true;
  }

  useEffect(() => {
    if (!isLoggedIn) navigate("/signin", { replace: true });
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    if (token?.data?.adminType === "Sub Admin") {
      const Slug = token?.data?.permissions.find(
        (item) => item.slug === location?.pathname
      );
      if (!Slug) {
        navigate(-1);
      }
    }
  }, []);

  return <>{children}</>;
};

export default AuthGuard;
