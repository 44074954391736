import {
  LEADERSHIP_LISTING_SUCCESS,
  LEADERSHIP_LISTING_FAIL,
  LEADERSHIP_ADD_SUCCESS,
  LEADERSHIP_ADD_FAIL,
  LEADERSHIP_EDIT_SUCCESS,
  LEADERSHIP_EDIT_FAIL,
  LEADERSHIP_UPDATE_SUCCESS,
  LEADERSHIP_UPDATE_FAIL,
} from "../constants/types";
const initialState = {
  leadershipList: {},
  addLeadership: {},
  editLeadership: {},
  updateLeadership: {},
};
const LeadershipReducer = (state = initialState, action) => {
  switch (action.type) {
    case LEADERSHIP_LISTING_SUCCESS:
      return {
        ...state,
        leadershipList: action.payload.data,
      };
    case LEADERSHIP_LISTING_FAIL:
      return {
        ...state,
        leadershipList: action.payload.message,
      };

    case LEADERSHIP_ADD_SUCCESS:
      return {
        ...state,
        addLeadership: action.payload.data,
      };
    case LEADERSHIP_ADD_FAIL:
      return {
        ...state,
        addLeadership: action.payload.message,
      };

    //EDIT event
    case LEADERSHIP_EDIT_SUCCESS:
      return {
        ...state,
        editLeadership: action.payload.data,
      };
    case LEADERSHIP_EDIT_FAIL:
      return {
        ...state,
        editLeadership: action.payload.message,
      };

    //EDIT event
    case LEADERSHIP_UPDATE_SUCCESS:
      return {
        ...state,
        updateLeadership: action.payload,
      };
    case LEADERSHIP_UPDATE_FAIL:
      return {
        ...state,
        updateLeadership: action.payload.message,
      };

    default:
      return state;
  }
};

export default LeadershipReducer;
