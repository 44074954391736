import { DASHBOARD_DAYGRAPH_S, DASHBOARD_F, DASHBOARD_S, DASHBOARD_YEARGRAPH_F, DASHBOARD_YEARGRAPH_S,} from "../constants/types";

const initialState = {
dashbord:{},
};

const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case DASHBOARD_S:
            return {
                ...state, dashbord: action.payload.data
            }
        case DASHBOARD_F:
            return {
                ...state, dashbord: action.payload.message
            }
            case DASHBOARD_YEARGRAPH_S:
                return {
                    ...state, years: action.payload.data
                }
            case DASHBOARD_YEARGRAPH_F:
                return {
                    ...state, years: action.payload.message
                }
                case DASHBOARD_DAYGRAPH_S:
                    return {
                        ...state, week: action.payload.data
                    }
                                
        default:
            return state;
    }
}
export default DashboardReducer