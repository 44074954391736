import { POST_COMMENT_LIST_F, POST_COMMENT_LIST_S, POST_LIST_F, POST_LIST_S, REPORT_POST_LIST_F, REPORT_POST_LIST_S, STRATEGIC_POST_LIST_F, STRATEGIC_POST_LIST_S } from "../constants/types";

const initialState = {
    postList:[],
    strategicPostList:[],
    reportpostList:[]
};

const PostReducer = (state = initialState, action) => {
    switch (action.type) {
        case POST_LIST_S:
            return {
                ...state, postList: action.payload.data
            }
        case POST_LIST_F:
            return {
                ...state, postList: action.payload.message
            }
            case POST_COMMENT_LIST_S:
                return {
                    ...state, comment: action.payload.data
                }
            case POST_COMMENT_LIST_F:
                return {
                    ...state, comment: action.payload.message
                }
                case STRATEGIC_POST_LIST_S:
                    return {
                        ...state, strategicPostList: action.payload.data
                    }
                case STRATEGIC_POST_LIST_F:
                    return {
                        ...state, strategicPostList: action.payload.message
                    }
                    case REPORT_POST_LIST_S:
                    return {
                        ...state, reportpostList: action.payload.data
                    }
                case REPORT_POST_LIST_F:
                    return {
                        ...state, reportpostList: action.payload.message
                    }
        default:
            return state;
    }
}
export default PostReducer