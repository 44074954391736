import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";
import { renderField } from "../../components/forms";
import Breadcrumb from "../../components/layout/Breadcrumb";
import "react-datepicker/dist/react-datepicker.css";
import "../Community/community.scss";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  servicechargesAction,
  servicechargesViewAction,
} from "../../actions/servicecharges";

const list = [
  {
    name: "Manage Service Charges",
    link: "",
    isactive: false,
  },
];

const ManagesserviceAdd = () => {
  const Change = useSelector((state) => state.auth?.user?.data);
  const dispatch = useDispatch();

  const [intialdata, setintialdata] = useState();
  useEffect(() => {
    dispatch(servicechargesViewAction())
      .then((res) => {
        setintialdata(res.data);
      })
      .catch((err) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (value) => {
    dispatch(
      servicechargesAction("63be97f17f3e07f08e69608b", {
        serviceCharge: value.serviceCharge,
        paymentProcesFee: value.paymentProcesFee,
      })
    )
      .then((res) => {
        toast.success(res.message);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <>
      <div>
        <div className="top-box">
          <Breadcrumb list={list} />
          <h2>Manage Service Charges </h2>
        </div>
        <div className="section-inner">
          <div className="form-area">
            <Form
              onSubmit={onSubmit}
              initialValues={{
                paymentProcesFee: intialdata?.paymentProcesFee || "",
                serviceCharge: intialdata?.serviceCharge || "",
              }}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="input-list d-flex flex-wrap">
                    <div className="col-12 p-0 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> Payment Processing Fee (%)
                      </label>
                      <Field
                        name="paymentProcesFee"
                        type="text"
                        placeholder="Payment Processing Fee"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                      />
                    </div>
                    <div className="col-12 p-0 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> Service Charge (%)
                      </label>
                      <Field
                        name="serviceCharge"
                        type="text"
                        placeholder="Service Charge"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box "
                      />
                    </div>
                  </div>

                  <div className="d-flex flex-wrap form-btn-box position-box ">
                    {Change?.adminType === "Sub Admin" ? (
                      ""
                    ) : (
                      <Link to="/" className="link-btn cancel">
                        Cancel
                      </Link>
                    )}
                    <button type="submit" className="link-btn">
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagesserviceAdd;
