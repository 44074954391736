import {
  ABOUT_LISTING_FAIL,
  ABOUT_LISTING_SUCCESS,
  ABOUT_UPDATE_FAIL,
  ABOUT_UPDATE_SUCCESS,
} from "../constants/types";

const initialState = {
  aboutList: {},
  updateAbout: {},
};
const AboutReducer = (state = initialState, action) => {
  switch (action.type) {
    case ABOUT_LISTING_SUCCESS:
      return {
        ...state,
        aboutList: action.payload.data,
      };
    case ABOUT_LISTING_FAIL:
      return {
        ...state,
        aboutList: action.payload.message,
      };

    //EDIT cause
    case ABOUT_UPDATE_SUCCESS:
      return {
        ...state,
        updateAbout: action.payload,
      };
    case ABOUT_UPDATE_FAIL:
      return {
        ...state,
        updateAbout: action.payload.message,
      };

    default:
      return state;
  }
};

export default AboutReducer;
