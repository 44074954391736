import React, { lazy, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthGuard from "../components/auth";
import ChangePassword from "../pages/ChangePassword";
import InCharitys from "../pages/Master/Charitys";
import ForgotPassword from "../pages/ForgotPassword";

import { useDispatch } from "react-redux";
import { storeMetaData } from "../actions/meta";
import SubscribedDonor from "../pages/SubscribedDonor";
import PendingStrategic from "../pages/Strategic/Pending Strategic Partner";
import ResetPassword from "../pages/ResetPassword";
import ManagesserviceAdd from "../pages/Manage Service Charges";
import Givingpartner from "../pages/Content/GivingPartnerPage";
import SelectTest from "../pages/SelectTest";

const Layout = lazy(() => import("../components/layout"));
const SignIn = lazy(() => import("../pages/SignIn"));
const Dashboard = lazy(() => import("../pages/Dashboard"));

const Master = lazy(() => import("../pages/Master"));
const MasterView = lazy(() => import("../pages/Master/Charitys/view/view"));

const CharityViews = lazy(() => import("../pages/Master/Charitys/view"));
const CharityEdit = lazy(() => import("../pages/Master/Charitys/edit"));
const CharityAdd = lazy(() => import("../pages/Master/Charitys/add"));

const CharityCategory = lazy(() => import("../pages/Master/CharityCategory"));

const Cause = lazy(() => import("../pages/Master/Cause"));
const CauseAdd = lazy(() => import("../pages/Master/Cause/add"));
const CauseEdit = lazy(() => import("../pages/Master/Cause/edit"));

const Community = lazy(() => import("../pages/Community"));
const CommunityAdd = lazy(() => import("../pages/Community/add"));
const CommunityView = lazy(() => import("../pages/Community/view"));
const CommunityEdit = lazy(() => import("../pages/Community/edit"));

const Chome = lazy(() => import("../pages/Content/home"));
const Policy = lazy(() => import("../pages/Content/policy"));
const Terms = lazy(() => import("../pages/Content/terms"));
const About = lazy(() => import("../pages/Content/about"));

const Leadership = lazy(() => import("../pages/Content/leadership"));
const AddLeadership = lazy(() => import("../pages/Content/leadership/add"));
const EditLeadership = lazy(() => import("../pages/Content/leadership/edit"));
const ViewLeadership = lazy(() => import("../pages/Content/leadership/view"));

const Event = lazy(() => import("../pages/Event"));
const EventAdd = lazy(() => import("../pages/Event/add"));
const EventEdit = lazy(() => import("../pages/Event/edit"));
const EventView = lazy(() => import("../pages/Event/view"));

const Strategic = lazy(() => import("../pages/Strategic"));
const StrategicView = lazy(() => import("../pages/Strategic/view"));

const SubAdmin = lazy(() => import("../pages/SubAdmin"));
const AdminAdd = lazy(() => import("../pages/SubAdmin/add"));
const AdminEdit = lazy(() => import("../pages/SubAdmin/edit"));
const AdminView = lazy(() => import("../pages/SubAdmin/view"));

const Donor = lazy(() => import("../pages/Donor"));
const DonorAdd = lazy(() => import("../pages/Donor/add"));
const DonorEdit = lazy(() => import("../pages/Donor/edit"));
const DonorView = lazy(() => import("../pages/Donor/view"));

const Category = lazy(() => import("../pages/Category"));

const ContactForm = lazy(() => import("../pages/Category/ContactForm"));
const ContactFormView = lazy(() =>
  import("../pages/Category/ContactForm/view")
);

const FAQ = lazy(() => import("../pages/Category/FAQ"));
const FAQAdd = lazy(() => import("../pages/Category/FAQ/add"));
const FAQEdit = lazy(() => import("../pages/Category/FAQ/edit"));

const Transaction = lazy(() => import("../pages/Transaction"));
const TransactionView = lazy(() => import("../pages/Transaction/view"));

const Badge = lazy(() => import("../pages/Badge"));
const BadgeEdit = lazy(() => import("../pages/Badge/edit"));

const Chat = lazy(() => import("../pages/Chat"));

const ReportePost = lazy(() => import("../pages/ReportePost"));

const Notifications = lazy(() => import("../pages/Notification"));

const Routing = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(storeMetaData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Routes>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/charity-signin" element={<SignIn charityAdmin={true} />} />
      <Route path="/forgot_password" element={<ForgotPassword />} />
      <Route path="/change_password" element={<ChangePassword />} />
      <Route path="/reset_password/:token" element={<ResetPassword />} />
      <Route
        path="/"
        element={
          <AuthGuard>
            <Layout />
          </AuthGuard>
        }
      >
        {/* Testingggggggggggggggggg */}
        <Route index path="/test" element={<SelectTest />} />

        {/* dashboard */}
        <Route index path="/dashboard" element={<Dashboard />} />
        {/* master */}
        <Route index path="/master" element={<Master />} />
        <Route index path="/master/master_view/:id" element={<MasterView />} />
        {/* Charitys */}
        <Route
          index
          path="/master/charity_management"
          element={<InCharitys />}
        />
        <Route index path="/master/charity_add" element={<CharityAdd />} />
        <Route
          index
          path="/master/charity_edit/:id"
          element={<CharityEdit />}
        />
        <Route index path="/master/charity_views" element={<CharityViews />} />
        {/* Charity Category */}
        <Route
          index
          path="/master/charity_category_management"
          element={<CharityCategory />}
        />
        <Route index path="/cause/cause_edit/:id" element={<CauseEdit />} />
        <Route index path="/cause/cause_add" element={<CauseAdd />} />
        {/* cause */}
        <Route index path="/master/cause_management" element={<Cause />} />
        <Route index path="/cause/cause_edit/:id" element={<CauseEdit />} />
        <Route index path="/cause/cause_add" element={<CauseAdd />} />
        {/* Community */}
        <Route index path="/community" element={<Community />} />
        <Route
          index
          path="/community/community_add"
          element={<CommunityAdd />}
        />
        <Route
          index
          path="/community/community_edit/:id"
          element={<CommunityEdit />}
        />
        <Route
          index
          path="/community/Community_view/:id"
          element={<CommunityView />}
        />
        {/* Content */}
        <Route index path="/content_management/homepage" element={<Chome />} />
        <Route
          index
          path="/content_management/privacy_policy"
          element={<Policy />}
        />
        <Route
          index
          path="/content_management/terms_condition"
          element={<Terms />}
        />
        <Route index path="/content_management/about_us" element={<About />} />
        {/* Leadership */}
        <Route
          index
          path="/content_management/leadership"
          element={<Leadership />}
        />
        <Route
          index
          path="/content_management/add_leadership"
          element={<AddLeadership />}
        />
        <Route
          index
          path="/content_management/edit_leadership/:id"
          element={<EditLeadership />}
        />
        <Route
          index
          path="/content_management/view_leadership/:id"
          element={<ViewLeadership />}
        />
        {/* Leadership */}
        <Route index path="/event" element={<Event />} />
        <Route index path="/event/event_add" element={<EventAdd />} />
        <Route index path="/event/event_edit/:id" element={<EventEdit />} />
        <Route index path="/event/event_view/:id" element={<EventView />} />
        {/* Strategic */}
        <Route index path="/strategic" element={<Strategic />} />
        <Route
          index
          path="/strategic/strategic_view/:id"
          element={<StrategicView />}
        />
        <Route
          index
          path="/strategic/pending_strategic_partner"
          element={<PendingStrategic />}
        />
        {/* Donor */}
        <Route index path="/donor" element={<Donor />} />
        <Route index path="/donor/donor_add" element={<DonorAdd />} />
        <Route index path="/donor/donor_edit/:id" element={<DonorEdit />} />
        <Route index path="/donor/donor_view/:id" element={<DonorView />} />
        {/* sub Admin */}
        <Route index path="/sub_admin" element={<SubAdmin />} />
        <Route index path="/sub_admin/sub_admin_add" element={<AdminAdd />} />
        <Route
          index
          path="/sub_admin/sub_admin_edit/:id"
          element={<AdminEdit />}
        />
        <Route
          index
          path="/sub_admin/sub_admin_view/:id"
          element={<AdminView />}
        />
        {/* categoty */}
        <Route index path="/category" element={<Category />} />
        <Route index path="/category/contact_form" element={<ContactForm />} />
        <Route
          index
          path="/category/contact_form/contact_form_view/:id"
          element={<ContactFormView />}
        />
        <Route index path="/category/faq" element={<FAQ />} />
        <Route index path="/category/faq/faq_edit/:id" element={<FAQEdit />} />
        <Route index path="/category/faq/faq_add" element={<FAQAdd />} />
        {/*  Transaction */}
        <Route index path="/transaction" element={<Transaction />} />
        <Route
          index
          path="/transaction/transaction_view/:id"
          element={<TransactionView />}
        />
        {/* Badge management */}
        <Route index path="/badge" element={<Badge />} />
        <Route index path="/badge/badge_edit/:id" element={<BadgeEdit />} />
        {/* ReportePost */}
        <Route index path="/reporte_post" element={<ReportePost />} />
        {/* Mange service Charge*/}
        <Route
          index
          path="/manage_service_charges"
          element={<ManagesserviceAdd />}
        />
        {/* Notifications management */}
        <Route index path="/send_notification" element={<Notifications />} />
        {/* chat */}
        <Route index path="/chat" element={<Chat />} />
        {/* Subscribed Donor */}
        <Route index path="/subscribed_donor" element={<SubscribedDonor />} />
        <Route path="/" element={<Navigate replace to="/dashboard" />} />
        {/* Giving Partner */}
        <Route index path="/giving_partner" element={<Givingpartner />} />
      </Route>
      <Route path="*" element={<Navigate replace to="/signin" />} />
    </Routes>
  );
};

export default Routing;
