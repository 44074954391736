import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  givingPartnerDetailAction,
  givingPartnerUpdateAction,
} from "../../../actions/cms";
import { doUploadImage } from "../../../actions/registration";
import ImgUploadComm from "../../../components/common/img-upload-test";
import Breadcrumb from "../../../components/layout/Breadcrumb";

const list = [
  {
    name: "Content Management ",
    link: "",
    isactive: false,
  },
  {
    name: "Giving Partner",
    link: "",
    isactive: true,
  },
];
const Givingpartner = () => {
  const userData = useSelector((state) => state.auth?.user?.data);
  const metaData = useSelector((state) => state.metaReducer);
  const GivingpartnerData = useSelector(
    (state) => state.CmsReducer.givingpartner
  );

  const dispatch = useDispatch();

  const [givingpartnerImages, setgivingPartnerImages] = useState();
  const [oldgivingpartnerImages, setoldGivingPartnerImages] = useState();

  const [contentdata, setcontentData] = useState("");

  useEffect(() => {
    dispatch(givingPartnerDetailAction()).then((res) => {
      setcontentData(res?.data?.content);
      setoldGivingPartnerImages(res?.data?.image);
    });
  }, []);

  const onSubmit = () => {
    if (givingpartnerImages) {
      const payload = {
        image: givingpartnerImages,
        moduleName: "givingPartnerimage",
      };
      dispatch(doUploadImage(payload)).then((res) => {
        updateGivingPartner(res.data.name);
      });
    } else {
      updateGivingPartner(oldgivingpartnerImages);
    }
  };

  const updateGivingPartner = (image) => {
    dispatch(
      givingPartnerUpdateAction({
        _id: GivingpartnerData?._id,
        content: contentdata.replace(/&nbsp;/g, " "),
        image,
      })
    ).then((res) => toast.success(res.message));
  };

  return (
    <div>
      <div className="top-box">
        <Breadcrumb list={list} />
        <h2> Giving Partner </h2>
      </div>
      <div className="section-inner">
        <div>
          <label className="label-text">
            {" "}
            <span>*</span> Giving Partner Image{" "}
          </label>
        </div>
        <ImgUploadComm
          multiple={false}
          setImages={setgivingPartnerImages}
          images={givingpartnerImages}
          oldImages={oldgivingpartnerImages}
          oldLogo={
            metaData?.mediaURL +
            "givingPartnerimage/" +
            GivingpartnerData?.image
          }
          moduleName="givingPartnerimage"
          callUploadAPI={false}
        />
        <div>
          <label className="label-text">
            {" "}
            <span>*</span> Giving Partner Content{" "}
          </label>
        </div>
        <div className="form-area">
          <ReactQuill
            theme="snow"
            placeholder={"write...."}
            value={contentdata}
            onChange={setcontentData}
            style={{
              height: "300px",
              display: "inline-block",
              width: "100%",
            }}
          />
          <div className="d-flex flex-wrap form-btn-box  position-box">
            {userData?.adminType === "Sub Admin" ? (
              ""
            ) : (
              <Link to="/dashboard" className="link-btn cancel">
                Cancel
              </Link>
            )}
            <button type="" className="link-btn" onClick={onSubmit}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Givingpartner;
