import {
  API_SUBSCRIBE_DONOR_LIST,
  API_SUBSCRIBE_ONETIMEDONOR_LIST,
} from "../constants/api";
import {
  API,
  SUBSCRIBE_DONOR_LIST_F,
  SUBSCRIBE_DONOR_LIST_S,
  SUBSCRIBE_ONETIMEDONOR_LIST_F,
  SUBSCRIBE_ONETIMEDONOR_LIST_S,
} from "../constants/types";

export const subscribeDonorlist = (data) => ({
  type: API,
  payload: {
    url: API_SUBSCRIBE_DONOR_LIST,
    method: "POST",
    data: data,
    success: (data) => ({
      type: SUBSCRIBE_DONOR_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: SUBSCRIBE_DONOR_LIST_F,
      payload: data,
    }),
  },
});

export const subscribeonetimeDonorlist = (data) => ({
  type: API,
  payload: {
    url: API_SUBSCRIBE_ONETIMEDONOR_LIST,
    method: "POST",
    data: data,
    success: (data) => ({
      type: SUBSCRIBE_ONETIMEDONOR_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: SUBSCRIBE_ONETIMEDONOR_LIST_F,
      payload: data,
    }),
  },
});
