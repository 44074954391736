import {
  CITY_S,
  COUNTRY_S,
  FETCH_META_F,
  FETCH_META_S,
  STATE_S,
} from "../constants/types";

const initialState = {
  mediaURL: undefined,
  placeholder: undefined,
  error: undefined,
  cityList: [],
  countryList: [],
  stateList: [],
};

const metaReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_META_S:
      return {
        ...state,
        error: undefined,
        mediaURL: action.payload.data.mediaURL,
        placeholder: action.payload.data.placeholder,
      };
    case FETCH_META_F:
      return {
        ...state,
        error: action.payload.error,
        mediaURL: undefined,
        placeholder: undefined,
      };
    case CITY_S:
      return {
        ...state,
        cityList: action.payload.data?.length
          ? action.payload.data.map((val) => {
              return { value: val.id, label: val.name };
            })
          : [],
      };
    case STATE_S:
      return {
        ...state,
        stateList: action.payload.data?.length
          ? action.payload.data.map((val) => {
              return { value: val.id, label: val.name };
            })
          : [],
      };
    case COUNTRY_S:
      return {
        ...state,
        countryList: action.payload.data?.length
          ? action.payload.data.map((val) => {
              return { value: val.id, label: val.name };
            })
          : [],
      };

    default:
      return state;
  }
};

export default metaReducer;
