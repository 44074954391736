import {
  API_CHARITY_CAUASE_LIST,
  API_NOTIFICATION_HISTORY,
  API_SEND_NOTIFICATION_TO_ALL_USERS,
} from "../constants/api";
import { API } from "../constants/types";

export const sendNotificationAction = (data) => ({
  type: API,
  payload: {
    url: API_SEND_NOTIFICATION_TO_ALL_USERS,
    method: "POST",
    hideLoader: false,
    data: data,
  },
});

export const notificationHistory = (data) => ({
  type: API,
  payload: {
    url: API_NOTIFICATION_HISTORY,
    method: "POST",
    hideLoader: false,
    data,
  },
});

export const charityCauseList = (data) => ({
  type: API,
  payload: {
    url: API_CHARITY_CAUASE_LIST,
    method: "POST",
    hideLoader: true,
    data: data,
  },
});
