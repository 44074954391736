export const { REACT_APP_API_BASE: API_BASE } = process.env;
export const { REACT_APP_API_IMAGE_BASE: UPLOAD_URL } = process.env;

export const API_LOGIN = "admin/charity/charity-login";

export const API_SUPER_ADMIN_LOGIN = "/admin-login/login";

export const API_META = "/auth/metaData";

export const API_CAUSE = "/admin/cause/getAll";

export const API_CATEGORY = "/admin/charity-category/getAll";

export const API_CHARITY_LISTING = "/admin/charity/getAll";

export const API_CHARITY_LISTING_ACCEPT = "/admin/charity/charityList";

export const API_CHARITY_DETAIL = "/admin/charity/get/";

export const API_CHARITY_VERIFICTAION = "/admin/charity/changeVerification";

export const API_IMAGE_UPLOAD = "/local-image/uploadImage";
export const API_VIDEO_UPLOAD = "/local-image/uploadVideo";

//Password
export const API_CHANGE_PASSWORD = "/admin/sub-admin/changePassword";
export const API_FORGOT_PASSWORD = "/admin/sub-admin/forgotPassword";
export const API_RESET_PASSWORD = "/admin/sub-admin/resetPassword";

export const API_REFERSH_TOKEN = "/v1/common/refreshToken";

//images
// export const API_IMAGE_UPLOAD = '/local-image/uploadImage'
export const API_IMAGE_DELETE = "/local-image/removeImage";

//videos
// export const API_VIDEO_UPLOAD = '/local-image/uploadVideo'
export const API_VIDEO_DELETE = "/local-image/removeVideo";

export const API_CHARITY_REGISTRATION = "/admin/charity/create";

//Common Api
export const API_COUNTRY = "/v1/common/getAllCountry";
export const API_STATE = "/v1/common/getAllState";
export const API_CITY = "/v1/common/getAllCities";

// Admin Cause
export const API_CAUSE_CREATE = "/admin/cause/create";
// export const API_CAUSE_GETALL = '/admin/cause/getAll'
export const API_CAUSE_GETALL = "/admin/cause/causeList";
export const API_CAUSE_DELETE_ID = "/admin/cause/delete";
export const API_CAUSE_GET_ID = "/admin/cause/get/";
export const API_CAUSE_UPDATE_ID = "/admin/cause/update/";
export const API_CAUSE_ACTIVEINACTIVE = "/admin/cause/activeStatus/";
export const API_CAUSE_EXPORTCSV = "/admin/cause/causeList-excel";

// Charity Category

export const API_CHARITY_CATEGORY_CREATE = "/admin/charity-category/create";
// export const API_CHARITY_CATEGORY_GETALL = '/admin/charity-category/getAll'
export const API_CHARITY_CATEGORY_GETALL =
  "/admin/charity-category/categoryList";
export const API_CHARITY_CATEGORY_GET_ID = "/admin/charity-category/get/";
export const API_CHARITY_CATEGORY_DELETE_ID = "/admin/charity-category/delete";
export const API_CHARITY_CATEGORY_UPDATE_ID = "/admin/charity-category/update/";
export const API_CHARITY_CATEGORY_ACTIVEINACTIVE =
  "/admin/charity-category/activeStatus/";

// --------------------------------

export const API_CHARITY_MANAGE_CREATE = "/admin/charity/create";
export const API_CHARITY_MANAGE_DELETE_ID = "/admin/charity/delete";
export const API_CHARITY_MANAGE_GET_ID = "/admin/charity/get/";
export const API_CHARITY_MANAGE_UPDATE_ID = "/admin/charity/update/";
export const API_CHARITY_MANAGE_ACTIVEINACTIVE =
  "/admin/charity/charityStatus/";
export const API_PAYMENT_DETAIL_GET =
  "/admin/payment-method/findPaymentMethodbyCharity/";

// Admin Cause
export const API_COMMUNITY_CREATE = "/admin/admin-community/create";
export const API_COMMUNITY_GETALL = "/admin/admin-community/communityList";
export const API_COMMUNITY_DELETE_ID = "/admin/admin-community/delete";
export const API_COMMUNITY_GET_ID = "/v1/community/getCommunityDetail/";
export const API_COMMUNITY_UPDATE_ID = "/admin/admin-community/update/";
export const API_COMMUNITY_HIDE = "/admin/admin-community/hideCommunity/";
// export const API_COMMUNITY_ACTIVEINACTIVE = '/admin/cause/activeStatus/'

// Admin Event
export const API_EVENT_CREATE = "/admin/event/addEvent";
export const API_EVENT_GETALL = "/admin/event/eventFilter";
export const API_EVENT_DELETE_ID = "/admin/event/deleteEvent";
export const API_EVENT_GET_ID = "/admin/event/findEvent/";
export const API_EVENT_UPDATE_ID = "/admin/event/editEvent/";
export const API_EVENT_MEMBER_LIST = "/v1/event/eventDetailsExport/";
export const API_EVENT_MEMBER_DELETE = "/v1/event/removeMember";

//about us
export const API_ABOUT_UPDATE = "/admin/aboutUs/updateAboutUs";
export const API_ABOUT_GET = "/v1/aboutUs/aboutUsList";

// Giving Partner
export const API_GIVING_PARTNER_UPDATE = "/admin/givingpartner-page/update";
export const API_GIVING_PARTNER_GET = "/admin/givingpartner-page/getDetails";

export const API_HOMEPAGE_UPDATE = "/admin/homepage/updateHomepage";
export const API_HOMEPAGE_DETAIL = "/admin/homepage/getHomepageDetails";

// Post
export const API_POST_ADD = "/v1/posts";
export const API_POST_List = "/v1/posts/getAllCommunityPostList/";
export const API_POST_UPATE = "/v1/posts/";
export const API_POST_DELETE = "/v1/posts/";
export const API_POST_HIDE = "/v1/posts/hidePost";
export const API_POST_ACTIVE_INACTIVE = "/v1/posts/activeInActivePost/";
export const API_POST_INACTIVE_LIST = "/v1/posts/InActivePostList/";
export const API_POST_LIKE_UNLIKE = "/v1/posts/likeUnlikePost/";
export const API_POST_COMMENT = "/v1/posts/postComment";
export const API_POST_COMMENT_REPLY = "/v1/posts/postCommentRply";
export const API_POST_COMMENT_LIST = "v1/posts/getPostCommnetList/";
export const API_POST_COMMENT_LIKE = "/v1/posts/likeUnlikeComment/";
export const API_REPORT_POST = "/admin/reportPost/reportPostList";
export const API_REPORT_POST_DELETE = "/admin/reportPost/deletePost/";

//Donor Mangement
export const API_DONOR_LIST = "/admin/users/userList";
export const API_DONOR_ADD = "/admin/users/create";
export const API_DONOR_DETAILS = "/admin/users/viewUserDetails";
export const API_DONOR_UPDATE = "/admin/users/update/";
export const API_DONOR_DELETE = "/admin/users/delete/";
export const API_DONOR_STATUS = "/admin/users/userStatus/"; // Admin Event
export const API_LEADERSHIP_CREATE = "/leadership/addLeadership";
export const API_LEADERSHIP_GETALL = "/leadership/listLeadership";
export const API_LEADERSHIP_DELETE_ID = "/leadership/deleteLeadership";
export const API_LEADERSHIP_GET_ID = "/leadership/getLeadership/";
export const API_LEADERSHIP_UPDATE_ID = "/leadership/updateLeadership";

//CMS ADMIN

export const API_CMS_CREATE = "/admin/cms-pages/createCms";
export const API_CMS_GET_ID = "/admin/cms-pages/getCms/";
export const API_CMS_UPDATE_ID = "/admin/cms-pages/updateCms/";
export const API_CMS_DELETE_ID = "/admin/cms-pages/deleteCms/";
export const API_CMS_CMSPAGES_LIST = "/admin/cms-pages/cmsPagesList";

//transcation
export const API_TRANSCATION_LIST = "/admin/transaction/transactionList";
export const API_TRANSCATION_LIST_EXCEL =
  "/admin/transaction/TransactionList-excel";
export const API_TRANSCATION_DETAIL = "/admin/transaction/get/";

// ContactUS
export const API_CONTACTUS_LIST = "/admin/ContactUs";
export const API_CONTACTUS_DETAIL = "/admin/ContactUs/getContactUs";

//FaQ Mangement
export const API_CATEGORY_ADD = "/admin/admin-faq-category/create";
export const API_CATEGORY_UPDATE = "/admin/admin-faq-category/update/";
export const API_CATEGORY_LIST = "/admin/admin-faq-category/faqCategoryList";
export const API_CATEGORY_DELETE = "/admin/admin-faq-category/delete/";
export const API_CATEGORY_DETAIL = "admin/admin-faq-category/get/";

export const API_FAQ_ADD = "/admin/admin-faq/create";
export const API_FAQ_UPDATE = "/admin/admin-faq/update/";
export const API_FAQ_DELETE = "/admin/admin-faq/delete/";
export const API_FAQ_LIST = "/admin/admin-faq/faqList";
export const API_FAQ_DETAIL = "admin/admin-faq/get/";

// Sub Admin
export const API_SUB_ADMIN_ADD = "/admin/sub-admin/create";
export const API_SUB_ADMIN_UPDATE = "/admin/sub-admin/update/";
export const API_SUB_ADMIN_DELETE = "/admin/sub-admin/delete/";
export const API_SUB_ADMIN_STATUS = "/admin/sub-admin/subAdminStatus/";
export const API_SUB_ADMIN_LIST = "/admin/sub-admin/subAdminList";
export const API_SUB_ADMIN_DETAIL = "/admin/sub-admin/get/";

// Strategic Partner
export const API_STRATEGIC_PARTNER_LIST =
  "/v1/strategic-partner/strategicPartnerList";
export const API_STRATEGIC_PARTNER_STATUS =
  "/v1/strategic-partner/strategicPartnerStatus/";
export const API_STRATEGIC_PARTNER_DETAIL = "/v1/strategic-partner/get/";
export const API_STRATEGIC_POST_LIST = "/v1/posts/employeePost/";
export const API_PENDING_STRATEGIC_LIST =
  "/admin/strategicpartner/pending-List";
export const API_APPROVE_REJECT_STRATEGIC_PARTNER =
  "/admin/strategicpartner/changeVerificationStatus";
export const API_DELETE_STRATEGIC_PARTNER =
  "/v1/strategic-partner/deleteByAdmin";

//Dashboard
export const API_ADMIN_DASHBOARD_DATA = "/admin/dashboard/dashboard-data";
export const API_ADMIN_DASHBOARD_DONOR_DAYGARPH =
  "/admin/dashboard/totalDonorUser";
export const API_ADMIN_DASHBOARD_DONOR_YEARGARPH =
  "/admin/dashboard/yearGraphDashboardUser";
export const API_ADMIN_DASHBOARD_CHARITY_DAYGRAPH =
  "admin/dashboard/totalReqCharity";
export const API_ADMIN_DASHBOARD_CHARITY_YEARGRAPH =
  "/admin/dashboard/yearGraphDashboardCharity";

//subscribe donor
export const API_SUBSCRIBE_DONOR_LIST = "/adminSubScribedDonor/subscribeDonor";
export const API_SUBSCRIBE_ONETIMEDONOR_LIST = "/adminSubScribedDonor/oneTime";

//Badge mangement
export const API_BADGE_LIST = "badge-management/badgeList";
export const API_BADGE_UPDATE = "/badge-management/badgeUpdate/";
export const API_BADGE_DETAIL = "/badge-management/findBadge/";

//service mangement
export const API_SERVICE_CHARGES_ADD = "/admin/setting-master/setting-master/";
export const API_SERVICE_CHARGES_VIEW = "/admin/setting-master/setting-master";

//Chat
export const API_CLEARE_CHAT_HISTORY = "/v1/chat/clearChatHistory/";
export const API_CHAT_CREATE_ROOM = "/v1/chat/createRoom";

//Notifications
export const API_SEND_NOTIFICATION_TO_ALL_USERS =
  "/v1/notification/sendNotificationAllUser";
export const API_NOTIFICATION_HISTORY = "/v1/notification/notificationHistory";
export const API_CHARITY_CAUASE_LIST =
  "/v1/notification/charityAndCauseNamesList";
