import {
    PENDING_STRATEGIC_POST_LIST_F,
    PENDING_STRATEGIC_POST_LIST_S,
  STRATEGIC_PARTNERF_F,
  STRATEGIC_PARTNER_S,
  STRATEGIC_USER_VIEW_F,
  STRATEGIC_USER_VIEW_S,
} from "../constants/types";

const initialState = {
  strategicpartnerlist: [],
  strategicUserView: {},
  pendingstrategic:[]
};

const StrategicReducer = (state = initialState, action) => {
  switch (action.type) {
    case STRATEGIC_PARTNER_S:
      return {
        ...state,
        strategicpartnerlist: action.payload.data,
      };
    case STRATEGIC_PARTNERF_F:
      return {
        ...state,
        strategicpartnerlist: action.payload.message,
      };

    case STRATEGIC_USER_VIEW_S:
      return {
        ...state,
        strategicUserView: action.payload.data,
      };
    case STRATEGIC_USER_VIEW_F:
      return {
        ...state,
        strategicUserView: action.payload.message,
      };
      case PENDING_STRATEGIC_POST_LIST_S:
      return {
        ...state,
        pendingstrategic: action.payload.data,
      };
    case PENDING_STRATEGIC_POST_LIST_F:
      return {
        ...state,
        pendingstrategic: action.payload.message,
      };
    default:
      return state;
  }
};
export default StrategicReducer;
