import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/layout/Breadcrumb";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import "../Master/Charitys/charitys.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./donor.scss";
import {
  subscribeDonorlist,
  subscribeonetimeDonorlist,
} from "../../actions/subscribe";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { amountFormate, debounce, formatPhoneNumber } from "../../utils";

const list = [
  {
    name: " Subscribed Donor Mangement",
    link: "/subscribed_donor",
    isactive: false,
  },
  {
    name: "Subscribed Donor  List",
    link: "/subscribed_donor",
    isactive: true,
  },
];
const customStyles = {
  headCells: {
    style: {
      paddingTop: "8px",
      paddingBottom: "8px",
    },
  },
  cells: {
    style: {
      paddingTop: "8px",
      paddingBottom: "8px",
    },
  },
};

const SubscribedDonor = () => {
  const onetimedonation = useSelector(
    (state) => state.SubscribeDonorReducer.onetimesubscribdodnor.onetimePayment
  );
  const subscribedonorlist = useSelector(
    (state) =>
      state.SubscribeDonorReducer.subscribedonorlist.subScriptionDonorList
  );
  const dispatch = useDispatch();

  const [onetime, setoneTime] = useState(true);
  const [totalRecords, settotalRecords] = useState(0);

  const [arg, setArg] = useState({
    page: 1,
    limit: 10,
    search: "",
    column: "",
    order: "",
  });

  useEffect(() => {
    if (onetime) {
      dispatch(subscribeonetimeDonorlist(arg)).then((res) => {
        settotalRecords(res?.data?.total_records || 0);
      });
    } else
      dispatch(subscribeDonorlist(arg)).then((res) => {
        settotalRecords(res?.data?.total_records || 0);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arg, onetime]);

  const columns = [
    {
      name: " Donor Name",
      selector: (row) => row.firstName + " " + row.lastName,
      sortable: true,
      sortName: "firstName",
    },
    {
      name: " Nonprofit Name/Community Name",
      selector: (row) => row.name,
    },
    {
      name: "Donor Email",
      selector: (row) => row.email,
    },
    {
      name: "Donor Phone Number",
      selector: (row) =>
        row.phoneNumber ? formatPhoneNumber(row.phoneNumber) : "",
    },

    {
      name: "Subscribtion Count",
      selector: (row) => row.subscribedDonorCount,
    },
    {
      name: "Total Amount",
      selector: (row) => (
        <div className="status-box">
          {row.totalAmount ? (
            <span className="amount">$ {amountFormate(row.totalAmount)}</span>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  const columnsonetime = [
    {
      name: " Donor Name",
      selector: (row) => row.firstName + " " + row.lastName,
      sortable: true,
      sortName: "firstName",
    },
    {
      name: " Nonprofit Name/Community Name",
      selector: (row) => row.name,
    },
    {
      name: "Donor Email",
      selector: (row) => row.email,
    },
    {
      name: "Donor Phone Number",
      selector: (row) =>
        row.phoneNumber ? formatPhoneNumber(row.phoneNumber) : "",
    },
    {
      name: "Total Amount",
      selector: (row) => (
        <div className="status-box">
          {row.totalAmount ? (
            <span className="amount">$ {amountFormate(row.totalAmount)}</span>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  const handlePerRowsChange = async (newPerPage, page) => {
    setArg({ ...arg, limit: newPerPage });
  };

  const handlePageChange = (page) => {
    setArg({ ...arg, page: page });
  };

  const onChangeFun = (e) => {
    setArg({ ...arg, search: e });
  };
  const optimizedFn = debounce(onChangeFun);

  const onSortChange = (column, direction) => {
    setArg({ ...arg, column: column?.sortName, order: direction });
  };

  return (
    <>
      <div className="donor-section">
        <div className="top-box">
          <Breadcrumb list={list} />
          <h2>Subscribed Donor Management </h2>
        </div>
        <div className="section-inner">
          <div className="subHeaderWrap">
            <div className="search-box">
              <input
                type="text"
                placeholder="Search By Donor Name"
                className="search-input"
                onChange={(e) => optimizedFn(e.target.value)}
              />
            </div>
            <div className="image-box">
              <Tabs>
                <TabList>
                  <Tab onClick={() => setoneTime(true)}>One Time User List</Tab>
                  <Tab onClick={() => setoneTime(false)}>
                    Subscribe Donor List
                  </Tab>
                </TabList>
                <TabPanel></TabPanel>
                <TabPanel></TabPanel>
              </Tabs>
            </div>
            {/* <div className="div-btn-box"></div> */}
          </div>
          <DataTable
            border
            columns={onetime ? columnsonetime : columns}
            data={onetime ? onetimedonation : subscribedonorlist}
            pagination
            onSort={onSortChange}
            sortServer
            paginationServer
            paginationTotalRows={totalRecords}
            onChangeRowsPerPage={handlePerRowsChange}
            customStyles={customStyles}
            onChangePage={handlePageChange}
            dense
            responsive={true}
          />
        </div>
      </div>
    </>
  );
};
export default SubscribedDonor;
