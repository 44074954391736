import React, { useEffect, useRef } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Charityverifictaion } from "../../../actions/charity";
import { renderField, rendertextarea } from "../../forms";
import "./delete.scss";
import { toast } from "react-toastify";
import {
  eventListing,
  eventDelete,
  eventMemberDelete,
  eventEdit,
} from "../../../actions/event";
import {
  communityDelete,
  communityEdit,
  communityhideunhide,
  communityList,
} from "../../../actions/community";
import { donorDelete, donorList } from "../../../actions/donor";
import {
  leadershipDelete,
  leadershipListing,
} from "../../../actions/leadership";
import {
  postActiveInActivelist,
  postDelete,
  postList,
  ReportPostDelete,
  ReportPostList,
} from "../../../actions/post";
import {
  FaqcategoryAdd,
  FaqcategoryDelete,
  FaqcategoryDetail,
  FaqcategoryList,
  FaqcategoryUpdate,
  FaqDelete,
  FaqList,
} from "../../../actions/cms";
import {
  strategicApprovePartnerlist,
  subAdminDelete,
  subAdminList,
} from "../../../actions/subadmin";
import {
  strategicPartnerAprroveReject,
  strategicPartnerDelete,
} from "../../../actions/strategic-partner";

const Dmodel = ({
  name,
  smallText,
  setmodel,
  ismodel,
  id,
  arg,
  from,
  inputs,
  Mclass,
  isMyPost,
  rejectresaon,
}) => {
  const communitydetail = useSelector(
    (state) => state.CommunityReducer.editCommunity.communityDetails
  );
  const categorydetail = useSelector(
    (state) => state.CmsReducer.faqcategorydetail
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef();
  useEffect(() => {
    if (from === "Categoryedit") {
      dispatch(FaqcategoryDetail(id));
    }
  }, [dispatch, from, id]);

  const onSubmit = (value) => {
    switch (from) {
      case "charityReject":
        dispatch(
          Charityverifictaion({
            charityId: id,
            adminVerification: "Reject",
            rejectReason: value.textfield,
          })
        )
          .then((res) => {
            toast.success(res?.message || "Success");
            navigate("/master");
          })
          .catch((res) => {
            toast.error(res?.message || "Something went wrong");
          });
        setmodel(false);
        break;

      case "event":
        dispatch(eventDelete({ id: id }))
          .then((res) => {
            dispatch(eventListing(arg));
            if (arg?.status) {
              toast.success("Event deleted Successfully");
            } else {
              toast.success("Event Restore Successfully");
            }
          })
          .catch((res) => toast.error(res.message));
        setmodel(false);
        break;

      case "community":
        dispatch(communityDelete(id))
          .then((res) => {
            dispatch(communityList(arg));
            if (arg?.status) {
              toast.success("Community deleted Successfully");
            } else {
              toast.success("Community Restore Successfully");
            }
          })
          .catch((err) => toast.error(err?.message || "Something went wrong"));
        setmodel(false);
        break;

      case "donor":
        dispatch(donorDelete(id))
          .then((res) => {
            dispatch(donorList(arg));
            toast.success(res.message);
          })
          .catch((err) => toast.error(err.message));
        setmodel(false);
        break;
      case "leadership":
        dispatch(leadershipDelete(id))
          .then((res) => {
            dispatch(leadershipListing(arg));
            toast.success(res?.message || "Success");
          })
          .catch((err) => toast.error(err?.message || "Something went wrong"));
        setmodel(false);
        break;
      case "post":
        dispatch(postDelete(id)).then((res) => {
          const data = {
            page: 1,
            limit: 100,
          };
          dispatch(postList(communitydetail?._id, data, !isMyPost));
          toast.success(res.message);
          setmodel(false);
        });
        break;
      case "inActivePost":
        dispatch(postDelete(id)).then((res) => {
          const data = {
            page: 1,
            limit: 1000000,
          };
          dispatch(postActiveInActivelist(communitydetail?._id, data));
          setmodel(false);
        });
        break;
      case "memberDelete":
        dispatch(eventMemberDelete({ _id: id }))
          .then((res) => {
            setmodel(false);

            if (arg?.status === "Interested") {
              dispatch(eventEdit(arg?._id, arg, 1));
            } else if (arg?.status === "Going") {
              dispatch(eventEdit(arg?._id, arg, 2));
            } else if (arg?.status === "Notgoing") {
              dispatch(eventEdit(arg?._id, arg, 3));
            }

            toast.success(res.message);
          })
          .catch((res) => {
            toast.error(res.message);
          });

        break;
      case "Categoryadd":
        dispatch(
          FaqcategoryAdd({ name: value.name, description: "abcd" })
        ).then((res) => {
          if (formRef?.current?.restart) {
            formRef.current?.restart({});
          }
          dispatch(FaqcategoryList(arg));
          toast.success(res.message);
          setmodel(false);
        });
        break;
      case "Categoryedit":
        dispatch(FaqcategoryUpdate(id, { name: value.name })).then((res) => {
          dispatch(FaqcategoryList(arg));
          toast.success(res.message);
          setmodel(false);
        });
        break;
      case "Categorydelete":
        dispatch(FaqcategoryDelete(id)).then((res) => {
          dispatch(FaqcategoryList(arg));
          toast.success(res.message);
          setmodel(false);
        });
        break;
      case "Faqdelete":
        dispatch(FaqDelete(id)).then((res) => {
          dispatch(FaqList(arg));
          toast.success(res.message);
          setmodel(false);
        });
        break;
      case "subadmin":
        dispatch(subAdminDelete(id)).then((res) => {
          dispatch(subAdminList(arg));
          toast.success(res.message);
          setmodel(false);
        });
        break;
      case "ReportPost":
        dispatch(ReportPostDelete(id)).then((res) => {
          dispatch(ReportPostList(arg));
          toast.success(res.message);
          setmodel(false);
        });
        break;
      case "hidecommunity":
        dispatch(communityhideunhide(id)).then((res) => {
          dispatch(communityEdit(id));
          toast.success(res.message);
          setmodel(false);
        });
        break;
      case "strategicReject":
        dispatch(
          strategicPartnerAprroveReject({
            strategicPartnerId: id,
            adminVerification: "Reject",
            rejectReason: value.textfield,
          })
        )
          .then((res) => {
            navigate("/strategic/pending_strategic_partner");
            setmodel(false);
            toast.success(res?.message);
          })
          .catch((res) => {
            toast.error(res?.message);
          });
        break;
      case "strategicApprove":
        const data = {
          strategicPartnerId: id,
          adminVerification: "Approve",
          rejectReason: "",
        };

        dispatch(strategicPartnerAprroveReject(data))
          .then((res) => {
            toast.success(res?.message);
            navigate("/strategic");
          })
          .catch((err) => {
            toast.error(err?.message);
          });
        break;
      case "Strategic":
        dispatch(
          strategicPartnerDelete({
            strategicPartnerId: id,
          })
        )
          .then((res) => {
            toast.success(res?.message);
            dispatch(strategicApprovePartnerlist(arg));
            setmodel(false);
          })
          .catch((err) => {
            toast.error(err?.message);
          });
        break;

      default:
        break;
    }
  };

  const required = (value) => {
    if (!value) {
      return "Please enter Name";
    }
    if (value && !value.trim()) {
      return "Please enter valid Name";
    }
  };

  const buttonTexts = () => {
    let btnText = "No";
    if (
      from === "Categoryadd" ||
      from === "Categoryedit" ||
      from === "strategicRejectview"
    ) {
      btnText = "Cancel";
    }
    return btnText;
  };
  const requiredreson = (value, fieldName = " ") =>
    value ? undefined : `Please Enter ${fieldName}`;

  return (
    <>
      <div className={`model-area ${Mclass} ${ismodel ? "open-model" : ""}`}>
        <div className="model-box">
          <div className="modele-text-box">
            <h2>{name}</h2>
            <p>{smallText}</p>
            {/* <input type='text' value={rejectReason} onChange={(e)=>setRejectReason(e.target.value)}/> */}
            <Form
              onSubmit={onSubmit}
              // validate={validate}
              initialValues={{
                name: from === "Categoryedit" ? categorydetail?.name : "",
                textfield: rejectresaon,
              }}
            >
              {({ handleSubmit, form }) => {
                formRef.current = form;
                return (
                  <form onSubmit={handleSubmit}>
                    <ul className="input-list">
                      {inputs && (
                        <li>
                          {/* <label className='label-text'> Guidestar / Candid Seal :  </label> */}
                          <div className="div-checkbox">
                            <div className="inner-checkbox">
                              <label className="label-text">
                                {" "}
                                <span>*</span> Name
                              </label>
                              <Field
                                name="name"
                                component={renderField}
                                placeholder="Name"
                                validate={required}
                                className="form-grp"
                                inputclass="input-box"
                              />
                            </div>
                          </div>
                        </li>
                      )}

                      {from === "charityReject" ||
                      from === "strategicReject" ||
                      from === "strategicRejectview" ? (
                        <li>
                          {/* <label className='label-text'> Guidestar / Candid Seal :  </label> */}
                          <div className="div-checkbox">
                            <div className="inner-checkbox">
                              <Field
                                name="textfield"
                                component={rendertextarea}
                                placeholder="Add reason"
                                readOnly={
                                  from === "strategicRejectview" ? true : false
                                }
                                validate={(value) =>
                                  requiredreson(value, "Add Reason")
                                }
                                className="form-grp"
                                inputclass="input-box text-area-box"
                              />
                            </div>
                          </div>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                    <div className="model-btn">
                      {/* here Change the condition becuse in pending GP rejected View model not close  */}
                      <button
                        type="button"
                        className="btn-box no"
                        onClick={() => {
                          setmodel(false);
                          form.restart();
                        }}
                      >
                        {buttonTexts()}
                      </button>

                      {from === "strategicRejectview" ? (
                        ""
                      ) : (
                        <button type="submit" className="btn-box">
                          {from === "Categoryadd" || from === "Categoryedit"
                            ? "Save"
                            : "Yes"}
                        </button>
                      )}
                      {/* onClick={hadlereject} */}
                    </div>
                  </form>
                );
              }}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dmodel;
