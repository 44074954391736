import {
  CHAT_ACTIVE_USER,
  CHAT_LIMIT_CHANGE,
  CHAT_LIST_F,
  CHAT_LIST_S,
  CHAT_PAGE_CHANGE,
  CHAT_USER_LIST_F,
  CHAT_USER_LIST_S,
  USER_CONNECTION_STATUS,
  USER_LIST_LIMIT_CHANGE,
} from "../constants/types";

const initialState = {
  isConnected: false,
  chatUserList: [],
  activeUser: {},
  chatList: [],
  userListlimit: 10, // Chat User List
  page: 1, // Chat History Page
  limit: 15, // Chat History Limit
};
const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_CONNECTION_STATUS:
      return {
        ...state,
        isConnected: action.payload,
      };
    case CHAT_USER_LIST_S:
      return {
        ...state,
        chatUserList: action.payload,
      };
    case CHAT_USER_LIST_F:
      return {
        ...state,
        chatUserList: [],
      };
    case CHAT_LIST_S:
      return {
        ...state,
        chatList: action.payload?.length ? action.payload.reverse() : [],
      };
    case CHAT_LIST_F:
      return {
        ...state,
        chatList: [],
      };
    case CHAT_ACTIVE_USER:
      return {
        ...state,
        activeUser: action.payload,
      };
    //add cause
    case CHAT_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload,
      };
    case CHAT_LIMIT_CHANGE:
      return {
        ...state,
        limit: action.payload,
      };
    case USER_LIST_LIMIT_CHANGE:
      return {
        ...state,
        userListlimit: action.payload,
      };
    default:
      return state;
  }
};

export default chatReducer;
