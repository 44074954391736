import {
  API_CATEGORY_ADD,
  API_CATEGORY_DELETE,
  API_CATEGORY_DETAIL,
  API_CATEGORY_LIST,
  API_CATEGORY_UPDATE,
  API_CMS_CMSPAGES_LIST,
  API_CMS_CREATE,
  API_CMS_DELETE_ID,
  API_CMS_GET_ID,
  API_CMS_UPDATE_ID,
  API_CONTACTUS_DETAIL,
  API_CONTACTUS_LIST,
  API_FAQ_ADD,
  API_FAQ_DELETE,
  API_FAQ_DETAIL,
  API_FAQ_LIST,
  API_FAQ_UPDATE,
  API_GIVING_PARTNER_GET,
  API_GIVING_PARTNER_UPDATE,
  API_HOMEPAGE_DETAIL,
  API_HOMEPAGE_UPDATE,
} from "../constants/api";
import {
  API,
  CMS_ADMIN_CMSPAGES_LIST_F,
  CMS_ADMIN_CMSPAGES_LIST_S,
  CMS_ADMIN_CREATE_F,
  CMS_ADMIN_CREATE_S,
  CMS_ADMIN_GETID_F,
  CMS_ADMIN_GETID_S,
  CMS_ADMIN_UPDATEID_F,
  CMS_ADMIN_UPDATEID_S,
  CONTACTUS_DETAIL_F,
  CONTACTUS_DETAIL_S,
  CONTACTUS_LIST_F,
  CONTACTUS_LIST_S,
  FAQ_CATEGORY_DETAILS_F,
  FAQ_CATEGORY_DETAILS_S,
  FAQ_CATEGORY_LIST_F,
  FAQ_CATEGORY_LIST_S,
  FAQ_DETAIL_F,
  FAQ_DETAIL_S,
  FAQ_LIST_F,
  FAQ_LIST_S,
  GIVING_PARTNER_DETAIL_FAIL,
  GIVING_PARTNER_DETAIL_SUCCESS,
  HOMEPAGE_DETAIL_F,
  HOMEPAGE_DETAIL_S,
  HOMEPAGE_UPDATE_F,
  HOMEPAGE_UPDATE_S,
} from "../constants/types";

export const homepageUpdate = (data) => ({
  type: API,
  payload: {
    url: API_HOMEPAGE_UPDATE,
    method: "POST",
    data: data,
    success: (data) => ({
      type: HOMEPAGE_UPDATE_S,
      payload: data,
    }),
    error: (data) => ({
      type: HOMEPAGE_UPDATE_F,
      payload: data,
    }),
  },
});

export const homepageDetail = () => ({
  type: API,
  payload: {
    url: API_HOMEPAGE_DETAIL,
    method: "GET",
    success: (data) => ({
      type: HOMEPAGE_DETAIL_S,
      payload: data,
    }),
    error: (data) => ({
      type: HOMEPAGE_DETAIL_F,
      payload: data,
    }),
  },
});

export const createCms = (data) => ({
  type: API,
  payload: {
    url: API_CMS_CREATE,
    method: "POST",
    data: data,
    success: (data) => ({
      type: CMS_ADMIN_CREATE_S,
      payload: data,
    }),
    error: (data) => ({
      type: CMS_ADMIN_CREATE_F,
      payload: data,
    }),
  },
});

export const getCmsId = (id) => ({
  type: API,
  payload: {
    url: API_CMS_GET_ID + id,
    method: "GET",
    // data : data,
    success: (data) => ({
      type: CMS_ADMIN_GETID_S,
      payload: data,
    }),
    error: (data) => ({
      type: CMS_ADMIN_GETID_F,
      payload: data,
    }),
  },
});
export const updateCmsId = (data, id) => ({
  type: API,
  payload: {
    url: API_CMS_UPDATE_ID + `${id}`,
    method: "POST",
    data: data,
    success: (data) => ({
      type: CMS_ADMIN_UPDATEID_S,
      payload: data,
    }),
    error: (data) => ({
      type: CMS_ADMIN_UPDATEID_F,
      payload: data,
    }),
  },
});

export const deleteCms = (id) => ({
  type: API,
  payload: {
    url: API_CMS_DELETE_ID + id,
    method: "DELETE",
    data: id,
  },
});

export const getCmsList = (data) => ({
  type: API,
  payload: {
    url: API_CMS_CMSPAGES_LIST,
    method: "POST",
    data: data,
    success: (data) => ({
      type: CMS_ADMIN_CMSPAGES_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: CMS_ADMIN_CMSPAGES_LIST_F,
      payload: data,
    }),
  },
});

export const ContactUSList = (data) => ({
  type: API,
  payload: {
    url: API_CONTACTUS_LIST,
    method: "POST",
    data: data,
    success: (data) => ({
      type: CONTACTUS_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: CONTACTUS_LIST_F,
      payload: data,
    }),
  },
});
export const ContactUSDetail = (data) => ({
  type: API,
  payload: {
    url: API_CONTACTUS_DETAIL,
    method: "POST",
    data: data,
    success: (data) => ({
      type: CONTACTUS_DETAIL_S,
      payload: data,
    }),
    error: (data) => ({
      type: CONTACTUS_DETAIL_F,
      payload: data,
    }),
  },
});

export const FaqcategoryAdd = (data) => ({
  type: API,
  payload: {
    url: API_CATEGORY_ADD,
    method: "POST",
    data: data,
  },
});

export const FaqcategoryUpdate = (id, data) => ({
  type: API,
  payload: {
    url: API_CATEGORY_UPDATE + id,
    method: "PATCH",
    data: data,
  },
});

export const FaqcategoryDelete = (id) => ({
  type: API,
  payload: {
    url: API_CATEGORY_DELETE + id,
    method: "DELETE",
  },
});

export const FaqcategoryList = (data) => ({
  type: API,
  payload: {
    url: API_CATEGORY_LIST,
    method: "POST",
    data: data,
    success: (data) => ({
      type: FAQ_CATEGORY_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: FAQ_CATEGORY_LIST_F,
      payload: data,
    }),
  },
});

export const FaqcategoryDetail = (id) => ({
  type: API,
  payload: {
    url: API_CATEGORY_DETAIL + id,
    method: "GET",
    success: (data) => ({
      type: FAQ_CATEGORY_DETAILS_S,
      payload: data,
    }),
    error: (data) => ({
      type: FAQ_CATEGORY_DETAILS_F,
      payload: data,
    }),
  },
});
export const FaqAdd = (data) => ({
  type: API,
  payload: {
    url: API_FAQ_ADD,
    method: "POST",
    data: data,
  },
});

export const FaqUpdate = (id, data) => ({
  type: API,
  payload: {
    url: API_FAQ_UPDATE + id,
    method: "PATCH",
    data: data,
  },
});

export const FaqDelete = (id) => ({
  type: API,
  payload: {
    url: API_FAQ_DELETE + id,
    method: "DELETE",
  },
});
export const FaqList = (data) => ({
  type: API,
  payload: {
    url: API_FAQ_LIST,
    method: "POST",
    data: data,
    success: (data) => ({
      type: FAQ_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: FAQ_LIST_F,
      payload: data,
    }),
  },
});

export const FaqDetail = (id) => ({
  type: API,
  payload: {
    url: API_FAQ_DETAIL + id,
    method: "GET",
    success: (data) => ({
      type: FAQ_DETAIL_S,
      payload: data,
    }),
    error: (data) => ({
      type: FAQ_DETAIL_F,
      payload: data,
    }),
  },
});

export const givingPartnerUpdateAction = (data) => ({
  type: API,
  payload: {
    url: API_GIVING_PARTNER_UPDATE,
    method: "POST",
    data:data
  },
});

export const givingPartnerDetailAction = () => ({
  type: API,
  payload: {
    url: API_GIVING_PARTNER_GET,
    method: "GET",
    success: (data) => ({
      type: GIVING_PARTNER_DETAIL_SUCCESS,
      payload: data,
    }),
    error: (data) => ({
      type: GIVING_PARTNER_DETAIL_FAIL,
      payload: data,
    }),
  },
});