import {
  CMS_ADMIN_CMSPAGES_LIST_F,
  CMS_ADMIN_CMSPAGES_LIST_S,
  CMS_ADMIN_CREATE_F,
  CMS_ADMIN_CREATE_S,
  CMS_ADMIN_DELETEID_F,
  CMS_ADMIN_DELETEID_S,
  CMS_ADMIN_GETID_F,
  CMS_ADMIN_GETID_S,
  CMS_ADMIN_UPDATEID_F,
  CMS_ADMIN_UPDATEID_S,
  CONTACTUS_DETAIL_F,
  CONTACTUS_DETAIL_S,
  CONTACTUS_LIST_F,
  CONTACTUS_LIST_S,
  FAQ_CATEGORY_DETAILS_F,
  FAQ_CATEGORY_DETAILS_S,
  FAQ_CATEGORY_LIST_F,
  FAQ_CATEGORY_LIST_S,
  FAQ_DETAIL_F,
  FAQ_DETAIL_S,
  FAQ_LIST_F,
  FAQ_LIST_S,
  GIVING_PARTNER_DETAIL_FAIL,
  GIVING_PARTNER_DETAIL_SUCCESS,
  HOMEPAGE_DETAIL_F,
  HOMEPAGE_DETAIL_S,
  HOMEPAGE_UPDATE_F,
  HOMEPAGE_UPDATE_S,
} from "../constants/types";

const initialState = {
  homepage: {},
  homepagedetail: {},
  cmsDetail: {},
  getIdCmsDetail: {},
  updateCmsDetail: {},
  deleteCmsDetail: {},
  getCmsDetail: {},
  contactUS: {},
  contactUsDetail: {},
  faqlist: [],
  faqdetail: {},
  faqcategorylist: [],
  faqcategorydetail: {},
  givingpartner: {},
};

const CmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOMEPAGE_UPDATE_S:
      return {
        ...state,
        homepage: action.payload.data,
      };
    case HOMEPAGE_UPDATE_F:
      return {
        ...state,
        homepage: action.payload.message,
      };
    case HOMEPAGE_DETAIL_S:
      return {
        ...state,
        homepagedetail: action.payload.data,
      };
    case HOMEPAGE_DETAIL_F:
      return {
        ...state,
        homepagedetail: action.payload.message,
      };
    case CMS_ADMIN_CREATE_S:
      return {
        ...state,
        cmsDetail: action.payload.data,
      };
    case CMS_ADMIN_CREATE_F:
      return {
        ...state,
        cmsDetail: action.payload.message,
      };
    case CMS_ADMIN_GETID_S:
      return {
        ...state,
        getIdCmsDetail: action.payload.data,
      };
    case CMS_ADMIN_GETID_F:
      return {
        ...state,
        getIdCmsDetail: action.payload.message,
      };
    case CMS_ADMIN_UPDATEID_S:
      return {
        ...state,
        updateCmsDetail: action.payload.data,
      };
    case CMS_ADMIN_UPDATEID_F:
      return {
        ...state,
        updateCmsDetail: action.payload.message,
      };
    case CMS_ADMIN_DELETEID_S:
      return {
        ...state,
        deleteCmsDetail: action.payload.data,
      };
    case CMS_ADMIN_DELETEID_F:
      return {
        ...state,
        deleteCmsDetail: action.payload.message,
      };
    case CMS_ADMIN_CMSPAGES_LIST_S:
      return {
        ...state,
        getCmsDetail: action.payload.data,
      };
    case CMS_ADMIN_CMSPAGES_LIST_F:
      return {
        ...state,
        getCmsDetail: action.payload.message,
      };
    case CONTACTUS_LIST_S:
      return {
        ...state,
        contactUS: action.payload.data,
      };
    case CONTACTUS_LIST_F:
      return {
        ...state,
        contactUS: action.payload.message,
      };
    case CONTACTUS_DETAIL_S:
      return {
        ...state,
        contactUsDetail: action.payload.data,
      };
    case CONTACTUS_DETAIL_F:
      return {
        ...state,
        contactUsDetail: action.payload.message,
      };
    case FAQ_LIST_S:
      return {
        ...state,
        faqlist: action.payload.data,
      };
    case FAQ_LIST_F:
      return {
        ...state,
        faqlist: action.payload.message,
      };
    case FAQ_DETAIL_S:
      return {
        ...state,
        faqdetail: action.payload.data,
      };
    case FAQ_DETAIL_F:
      return {
        ...state,
        faqdetail: action.payload.message,
      };
    case FAQ_CATEGORY_LIST_S:
      return {
        ...state,
        faqcategorylist: action.payload.data,
      };
    case FAQ_CATEGORY_LIST_F:
      return {
        ...state,
        faqcategorylist: action.payload.message,
      };
    case FAQ_CATEGORY_DETAILS_S:
      return {
        ...state,
        faqcategorydetail: action.payload.data,
      };
    case FAQ_CATEGORY_DETAILS_F:
      return {
        ...state,
        faqcategorydetail: action.payload.message,
      };
    case GIVING_PARTNER_DETAIL_SUCCESS:
      return {
        ...state,
        givingpartner: action.payload.data,
      };
    case GIVING_PARTNER_DETAIL_FAIL:
      return {
        ...state,
        givingpartner: action.payload.message,
      };
    default:
      return state;
  }
};
export default CmsReducer;
